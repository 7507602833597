import styled from 'styled-components'

import { ButtonLink } from 'lib/ui'

const Links = () => {
  return (
    <Wrapper>
      <ButtonLink to="/herd">
        <i className="fas fa-farm" />
        <span>Herd</span>
      </ButtonLink>
      <ButtonLink to="/herd">
        <i className="fas fa-cog" />
        <span>Settings</span>
      </ButtonLink>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  z-index: 2;
  position: fixed;
  top: ${({ theme }) => theme.space.normal};
  right: ${({ theme }) => theme.space.normal};
  display: flex;
  align-items: center;
  justify-content: center;

  & > :not(:last-child) {
    margin-right: ${({ theme }) => theme.space.normal};
  }
`

export default Links
