import { useState, useMemo, useCallback } from 'react'
import { v4 as createUuid } from 'uuid'
import { DefaultTheme } from 'styled-components'

import { Marking } from 'lib/ui'
import { usePlayback } from 'lib/streamer'
import { AnimatePresence } from 'framer-motion'

// Replace with apollo state
const fakeMarkings = [
  {
    id: '1',
    name: 'new area',
    type: 'area',
    category: 'none',
    color: 'gray3' as const,
    points: [
      [0.9401082530337657, -0.2343586039828252, -0.24753286108519534],
      [0.6232005754336741, -0.7775736338129152, -0.08366771646289808],
      [0.7530553500364049, -0.2972194076445688, 0.5869993726580629]
    ] as [x: number, y: number, z: number][]
  },
  {
    id: '2',
    name: 'new line',
    type: 'line',
    category: 'none',
    color: 'gray3' as const,
    points: [
      [0.9401082530337657, -0.2343586039828252, -0.24753286108519534],
      [0.6232005754336741, -0.7775736338129152, -0.08366771646289808]
    ] as [x: number, y: number, z: number][]
  }
]

const Edit = () => {
  /* Instanciate with apollo data */
  const { playingCamera } = usePlayback()
  const [markings, setMarkings] = useState<
    {
      id: string
      type: 'area' | 'line'
      name: string
      category: string
      color: keyof DefaultTheme['color']
      points: [x: number, y: number, z: number][]
    }[]
  >([...(fakeMarkings as any)])

  const [changesBatch, setChangesBatch] = useState<{
    [id: string]: 'deleted' | 'added' | 'updated'
  }>({})

  const [polygons, lines] = useMemo(() => {
    const polygons = markings.filter(({ type }) => type === 'area')
    const lines = markings.filter(({ type }) => type === 'line')
    return [polygons, lines]
  }, [markings])
  const [selectedMarking, setSelectedMarking] = useState<ArrayElement<
    typeof markings
  > | null>(null)
  const [showModal, setShowModal] = useState(false)

  const onSave = useCallback(() => {
    console.log({ changesBatch, playingCamera })
  }, [changesBatch, playingCamera])

  const onPointsChange = useCallback(
    (id: string, points: [x: number, y: number, z: number][]) => {
      setMarkings((markings) =>
        markings.map((marking) =>
          marking.id === id ? { ...marking, points } : marking
        )
      )

      setChangesBatch((changes) =>
        changes?.[id] === 'added' ? changes : { ...changes, [id]: 'updated' }
      )
    },
    []
  )

  const onMarkingAdd = useCallback((type: 'area' | 'line') => {
    const id = createUuid()
    const marking: ArrayElement<typeof markings> = {
      id,
      type,
      name: type === 'area' ? 'new area' : 'new line',
      category: 'none',
      color: 'white',
      points:
        type === 'area'
          ? [
              [0.9401082530337657, -0.2343586039828252, -0.24753286108519534],
              [0.6232005754336741, -0.7775736338129152, -0.08366771646289808],
              [0.7530553500364049, -0.2972194076445688, 0.5869993726580629]
            ]
          : [
              [0.9401082530337657, -0.2343586039828252, -0.24753286108519534],
              [0.6232005754336741, -0.7775736338129152, -0.08366771646289808]
            ]
    }
    setMarkings((markings) => [...markings, marking])
    setSelectedMarking(marking)
    setChangesBatch((changes) => ({ ...changes, [id]: 'added' }))
  }, [])

  const onMarkingDelete = useCallback(() => {
    if (selectedMarking) {
      setMarkings((markings) =>
        markings.filter((marking) => marking.id !== selectedMarking.id)
      )
      setChangesBatch((changes) =>
        changes?.[selectedMarking.id] === 'added'
          ? (() => {
              const clone = { ...changes }
              delete clone[selectedMarking.id]
              return { ...clone }
            })()
          : { ...changes, [selectedMarking.id]: 'deleted' }
      )
      setSelectedMarking(null)
    }
  }, [selectedMarking])

  const onMarkingCustomize = useCallback(
    ({ name, category, color }) => {
      if (selectedMarking) {
        setMarkings((markings) =>
          markings.find((marking) => marking.id === selectedMarking.id)
            ? markings.map((marking) =>
                marking.id === selectedMarking.id
                  ? {
                      ...selectedMarking,
                      name,
                      category,
                      color
                    }
                  : marking
              )
            : [...markings, { ...selectedMarking, name, category, color }]
        )
        setShowModal(false)
        setChangesBatch((changes) =>
          changes?.[selectedMarking.id] === 'added'
            ? changes
            : { ...changes, [selectedMarking.id]: 'updated' }
        )
      }
    },
    [selectedMarking]
  )

  return (
    <>
      <group>
        {polygons.map((marking) => (
          <Marking.Polygon
            id={marking.id}
            key={marking.id}
            points={marking.points}
            selected={marking.id === selectedMarking?.id}
            onSelect={() => setSelectedMarking(marking)}
            color={marking.color}
            onChanged={onPointsChange}
          />
        ))}

        {lines.map((marking) => (
          <Marking.Line
            id={marking.id}
            key={marking.id}
            points={marking.points}
            selected={marking.id === selectedMarking?.id}
            color={marking.color}
            onSelect={() => setSelectedMarking(marking)}
            onChanged={onPointsChange}
          />
        ))}
      </group>
      <Marking.Toolbox.Sidebar
        onAdd={onMarkingAdd}
        showOptions={!!selectedMarking}
        onEdit={() => setShowModal(true)}
        onDelete={onMarkingDelete}
        onSave={onSave}
      />
      <AnimatePresence>
        {showModal && selectedMarking && (
          <Marking.Toolbox.Modal
            type={selectedMarking.type}
            category={selectedMarking.category}
            name={selectedMarking.name}
            color={selectedMarking.color}
            onComplete={onMarkingCustomize}
            onLeave={() => setShowModal(false)}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default Edit
