import styled from 'styled-components'

const TextInput = styled.input.attrs({ type: 'text' })`
  height: 40px;
  padding: ${({ theme }) => `0 ${theme.space.normal}`};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.gray5};
  border-radius: ${({ theme }) => theme.borderRadius};
  border: none;
  font-size: inherit;
  transition: 0.25s background;

  &:focus {
    background: ${({ theme }) => theme.color.gray4};
  }
`

export default TextInput
