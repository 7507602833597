import {
  FC,
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback
} from 'react'
import { fromEvent } from 'rxjs'
import {
  DefaultTheme,
  ThemeProvider as StyledProvider
} from 'styled-components'

import { light, dark } from './modes'

const initialTheme: DefaultTheme = {
  color: light,
  font: {
    size: {
      root: '10px',
      xSmall: '1rem',
      small: '1.4rem',
      normal: '1.6rem',
      large: '1.8rem'
    }
  },
  space: {
    small: '8px',
    normal: '16px',
    large: '32px'
  },
  borderRadius: '20px',
  backdropFilter: 'blur(20px)',
  hexOpacity: {
    '10%': '1A',
    '20%': '33',
    '30%': '4d',
    '40%': '66',
    '50%': '80',
    '60%': '99',
    '70%': 'B3',
    '80%': 'CC',
    '90%': 'E6'
  },
  timeline: {
    height: {
      collapsed: '40px',
      expanded: '120px'
    }
  }
}

const prefersMode =
  (localStorage.getItem('prefers-color-scheme') as
    | 'light'
    | 'dark'
    | 'auto'
    | null) || 'auto'
const prefersDarkColorSchemeMedia = matchMedia('(prefers-color-scheme: dark)')

const useContextValue = () => {
  const [mode, setMode] = useState<'dark' | 'light' | 'auto'>(prefersMode)
  const [appTheme, setAppTheme] = useState<DefaultTheme['color']>(
    prefersMode === 'auto'
      ? prefersDarkColorSchemeMedia.matches
        ? dark
        : light
      : prefersMode === 'dark'
      ? dark
      : light
  )

  const toggleMode = useCallback((newMode: typeof mode) => {
    localStorage.setItem('prefers-color-scheme', newMode)
    setMode(newMode)
  }, [])

  useEffect(() => {
    if (mode === 'auto') {
      const subscriber = fromEvent<MediaQueryListEvent>(
        prefersDarkColorSchemeMedia,
        'change'
      ).subscribe((e) => {
        setAppTheme(e.matches ? dark : light)
      })
      return () => subscriber.unsubscribe()
    } else {
      setAppTheme(mode === 'dark' ? dark : light)
    }
  }, [mode])

  const theme = useMemo<DefaultTheme>(
    () => ({
      ...initialTheme,
      color: {
        ...appTheme
      }
    }),
    [appTheme]
  )

  return { theme, mode, toggleMode }
}

const ThemeContext = createContext<ReturnType<typeof useContextValue>>({
  theme: initialTheme,
  mode: 'auto',
  toggleMode: () => {}
})

const ThemeProvider: FC = ({ children }) => {
  const value = useContextValue()

  return (
    <ThemeContext.Provider value={value}>
      <StyledProvider theme={value.theme}>{children}</StyledProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeProvider }
export default ThemeContext
