import { StrictMode } from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import App from 'core'

const targetElement = document.querySelector('#app')

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  targetElement
)

serviceWorkerRegistration.register()
reportWebVitals()
