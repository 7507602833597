import { FC, createContext, useState, useMemo, useLayoutEffect } from 'react'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router'

const useContextValue = () => {
  const history = useMemo(() => createBrowserHistory(), [])

  const [{ action, location }, setState] = useState({
    action: history.action,
    location: history.location
  })

  useLayoutEffect(() => history.listen(setState), [history])

  return { action, location, history }
}

// TODO: change this as any shit
const HistoryContext = createContext<ReturnType<typeof useContextValue>>({
  action: null,
  location: null,
  history: null
} as any)

const HistoryProvider: FC = ({ children }) => {
  const { location, action, history } = useContextValue()

  return (
    <HistoryContext.Provider value={{ location, action, history }}>
      <Router location={location} action={action} navigator={history}>
        {children}
      </Router>
    </HistoryContext.Provider>
  )
}

export { HistoryProvider }
export default HistoryContext
