import { ComponentPropsWithoutRef, forwardRef } from 'react'
import { Html } from '@react-three/drei'
import { ThemeProvider } from 'styled-components'

import { useTheme } from 'lib/ui'

interface Props extends ComponentPropsWithoutRef<typeof Html> {}
type Ref = HTMLDivElement

const ThreeHtml = forwardRef<Ref, Props>(({ children, ...rest }, ref) => {
  const { theme } = useTheme()
  return (
    <Html ref={ref} {...rest}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Html>
  )
})

export default ThreeHtml
