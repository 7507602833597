import { FC, useMemo } from 'react'
import { Outlet, useResolvedPath, matchPath, PathMatch } from 'react-router'
import styled from 'styled-components'

import { useHistory } from 'lib/history'

import Link from './link'
import Spacer from './spacer'

interface Props {
  sidebar?: { title: string; links: { label: string; to: string }[] }
  header?: { text: string; path: string; back?: string }[]
}

const PagesMenu: FC<Props> = ({
  sidebar = { title: '', links: [] },
  header = []
}) => {
  const { location } = useHistory()
  const resolvedPath = useResolvedPath('.')

  const { title, back } = useMemo(() => {
    let matchingPath: PathMatch | null = null
    let matchingTitle: string | null = null
    let matchingBackTo: string | null = null

    for (const { path, text, back } of header) {
      const absolutePath = path.startsWith('/')
        ? path
        : `${resolvedPath.pathname}/${path}`
      const match = matchPath(absolutePath, location.pathname)
      if (match) {
        matchingPath = match
        matchingTitle = text
        matchingBackTo = back || null
        break
      }
    }
    const title =
      matchingTitle?.replaceAll(/{(.*?)}/g, (e) => {
        const paramName = e.trim().slice(1, -1)
        const paramValue = matchingPath?.params?.[paramName]
        return paramValue ? `${paramValue}` : ''
      }) || ''
    return { title, back: matchingBackTo }
  }, [location.pathname, resolvedPath.pathname, header])

  return (
    <Wrapper>
      <Sidebar>
        <Link to="/" className="close-button">
          <i className="fas fa-times" />
        </Link>

        <h1>{sidebar.title}</h1>

        <nav>
          <ul>
            {sidebar.links.map(({ label, to }) => (
              <li key={to}>
                <Link to={to}>{label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </Sidebar>

      <Content>
        <Link
          to={back || ''}
          className="back-button"
          style={{ visibility: back ? 'initial' : 'hidden' }}
        >
          <i className="fas fa-arrow-left" />
        </Link>
        <ContentHeader>
          <h1>{title}</h1>
        </ContentHeader>
        <Outlet />
        <Spacer />
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => `calc(${theme.space.normal} * 2 + 40px)`};
  right: ${({ theme }) => theme.space.normal};
  width: ${({ theme }) => `calc(100% - ${theme.space.normal} * 2)`};
  bottom: ${({ theme }) => theme.space.normal};
  background: ${({ theme }) => `${theme.color.gray6}`};
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  z-index: 21;
`

const Sidebar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${({ theme }) => `${theme.color.gray5}`};

  @media (max-width: 1093px) {
    display: none;
  }

  & > h1 {
    padding: ${({ theme }) => theme.space.normal};
  }

  & a {
    color: inherit;
    text-decoration: none;

    &:not(.close-button) {
      padding: ${({ theme }) => theme.space.normal};
    }

    &.close-button {
      margin: ${({ theme }) => theme.space.normal};
    }
  }

  nav {
    ul {
      list-style: none;

      li {
        margin: ${({ theme }) => theme.space.normal} 0;
      }

      li > a {
        color: ${({ theme }) => theme.color.gray1};
        transition: color 0.25s;

        &.active {
          color: ${({ theme }) => theme.color.gray};
        }

        &:hover {
          color: ${({ theme }) => theme.color.gray};
        }
      }
    }
  }
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 300px;
  right: 0;
  bottom: 0;
  padding: ${({ theme }) => `0 ${theme.space.normal} ${theme.space.normal}`};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0px;
  }

  .back-button {
    margin: ${({ theme }) => `${theme.space.normal} 0`};
  }

  @media (max-width: 1093px) {
    left: 0;
  }
`

const ContentHeader = styled.header`
  display: flex;
  align-items: flex-end;
  margin: ${({ theme }) => `${theme.space.normal} 0`};
`

export default PagesMenu
