import { useQuery } from '@apollo/client'
import { useParams } from 'react-router'
import styled from 'styled-components'

import {
  ANIMAL_QUERY,
  AnimalQueryVariables,
  Animal as AnimalType
} from 'lib/graphql'

import { Notifications, Graphs } from 'lib/ui/animal'
import { logs } from 'lib/assets'

const getAge = (timestamp: number) => ~~((Date.now() - timestamp) / 31557600000)

const Animal = () => {
  const { id } = useParams()

  const { data } = useQuery<{ animal: AnimalType }, AnimalQueryVariables>(
    ANIMAL_QUERY,
    { variables: { id } }
  )

  return (
    <>
      {data && (
        <>
          <Grid>
            <Section gridArea="infos">
              <h2>Infos</h2>
              <p>
                Age: <strong>{getAge(data.animal.birth)} years old</strong>
              </p>
              <p>
                Stage of lactation: <strong>{data.animal.lactation}</strong>
              </p>
              <p>
                BCS: <strong>{3.5}</strong>
              </p>
              <p>
                Pregnancy: <strong>45 days (IA: 20/09/2020)</strong>
              </p>
            </Section>

            <Section gridArea="notifications">
              <h2>Notifications</h2>
              <Notifications notifications={data.animal.notifications} />
            </Section>

            <Section gridArea="graph">
              <h2>Graphs</h2>
              <Graphs />
            </Section>

            <Section gridArea="logs">
              <h2>Logs</h2>
              <img src={logs} alt="logs" />
            </Section>
          </Grid>
        </>
      )}
    </>
  )
}

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas:
    'infos notifications'
    'graph graph'
    'logs logs';
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.normal};
  margin-top: ${({ theme }) => theme.space.large};
`

const Section = styled.section<{ gridArea: string }>`
  padding: ${({ theme }) => `${theme.space.large} ${theme.space.normal}`};
  border-radius: 20px;
  transition: opacity 0.25s;
  grid-area: ${({ gridArea }) => gridArea};
  background: ${({ theme }) => `${theme.color.gray5}`};

  h2 {
    margin-bottom: ${({ theme }) => theme.space.normal};
  }
`

export default Animal
