import { useState, useRef, useEffect } from 'react'
import { fromEvent } from 'rxjs'

const useClickOutside = <El extends HTMLElement>(cb: () => any) => {
  const [listen, setListen] = useState(false)
  const ref = useRef<El>(null)

  useEffect(() => {
    if (listen) {
      const subscriber = fromEvent<MouseEvent>(document, 'click').subscribe(
        (e) => {
          if (ref.current && !ref.current?.contains(e.target as Node | null)) {
            cb()
          }
        }
      )

      return () => subscriber.unsubscribe()
    }
  }, [ref, cb, listen])

  return [ref, setListen] as const
}

export default useClickOutside
