import styled from 'styled-components'

interface Props {
  expandedMenu: boolean
}
const Icon = styled.i.attrs<Props, Props>(({ expandedMenu }) => ({
  className: expandedMenu ? 'fas fa-times' : 'fas fa-binoculars',
  expandedMenu: false
}))`
  width: 40px;
  height: 40px;
  background: ${({ theme }) => `${theme.color.gray5}`};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Icon
