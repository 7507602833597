import { useRef, useLayoutEffect } from 'react'
import { fromEvent } from 'rxjs'
import { PerspectiveCamera as CameraType, Vector3 } from 'three'
import { useThree } from 'react-three-fiber'
import { PerspectiveCamera } from '@react-three/drei'

const [MAX_ZOOM, MIN_ZOOM] = [3, 1]
const FOV = 80
const NEAR = 10
const FAR = 100_000
const POSITION = new Vector3(0, 0, 0.00001).normalize().negate()

const Camera = () => {
  const ref = useRef<CameraType>()
  const {
    gl: { domElement }
  } = useThree()

  useLayoutEffect(() => {
    // @ts-ignore
    window.camera = ref.current
    const subscriber = fromEvent<WheelEvent>(domElement, 'wheel').subscribe(
      ({ deltaY, clientX, clientY }) => {
        if (ref.current) {
          const zoomAction = deltaY < 0 ? 'out' : deltaY > 0 ? 'in' : 'none'
          if (
            (zoomAction === 'out' && ref.current.zoom === MIN_ZOOM) ||
            (zoomAction === 'in' && ref.current.zoom === MAX_ZOOM)
          ) {
            return
          }
          const computedValue = ref.current.zoom + deltaY * 0.0025

          const x = (clientX / domElement.width) * 2 - 1
          const y = -((clientY / domElement.height) * 2 - 1)
          const targetVectorLength = Math.abs(
            ref.current.position.y >= 0.999 ? 0.001 : 0.0015 * deltaY
          )

          const target = new Vector3(x, y, ref.current.position.z)
            .unproject(ref.current)
            .sub(ref.current.position)
            .setLength(targetVectorLength)
            .divideScalar(2)

          if (zoomAction === 'in') {
            ref.current.zoom = Math.min(computedValue, MAX_ZOOM)
            ref.current.position.subVectors(ref.current.position, target)
          } else if (zoomAction === 'out') {
            ref.current.zoom = Math.max(computedValue, MIN_ZOOM)
            ref.current.position.addVectors(ref.current.position, target)
          }

          ref.current.position.normalize()
          ref.current.updateProjectionMatrix()
          ref.current.dispatchEvent({ type: 'zoom' })
        }
      }
    )

    return () => subscriber.unsubscribe()
  }, [domElement])

  return (
    <PerspectiveCamera
      makeDefault
      ref={ref}
      fov={FOV}
      near={NEAR}
      far={FAR}
      position={POSITION}
      matrixAutoUpdate
    />
  )
}

export default Camera
