import styled from 'styled-components'

const Button = styled.button`
  height: 40px;
  padding: ${({ theme }) => `0 ${theme.space.normal}`};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => `${theme.color.gray5}`};
  border-radius: ${({ theme }) => theme.borderRadius};
  border: none;
  font-size: inherit;
  font-weight: bold;
  cursor: pointer;
  transition: 0.25s background;

  &:hover {
    background: ${({ theme }) => `${theme.color.gray4}`};
  }

  & > :not(:last-child) {
    margin-right: ${({ theme }) => theme.space.small};
  }
`

export default Button
