import {
  useState,
  useCallback,
  useRef,
  useLayoutEffect,
  useEffect
} from 'react'
import { combineLatest } from 'rxjs'
import { useLazyQuery } from '@apollo/client'
import { scaleTime } from 'd3-scale'
import { AnimatePresence, motion, PanInfo } from 'framer-motion'
import styled, { keyframes } from 'styled-components'

import { useReactive } from 'lib/utils'
import { usePlayback, useStreamer } from 'lib/streamer'
import {
  ArchivePeriods,
  ArchivePeriodsVariables,
  ARCHIVE_PERIODS_QUERY
} from 'lib/graphql'
import { useTheme } from 'lib/ui'

type RoundRectArgs = [
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius?: number,
  fill?: boolean,
  stroke?: boolean
]
function roundRect(
  ...[
    ctx,
    x,
    y,
    width,
    height,
    radius = 5,
    fill = true,
    stroke = false
  ]: RoundRectArgs
) {
  ctx.beginPath()
  ctx.moveTo(x + radius, y)
  ctx.lineTo(x + width - radius, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
  ctx.lineTo(x + width, y + height - radius)
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
  ctx.lineTo(x + radius, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
  ctx.lineTo(x, y + radius)
  ctx.quadraticCurveTo(x, y, x + radius, y)
  ctx.closePath()
  if (fill) {
    ctx.fill()
  }
  if (stroke) {
    ctx.stroke()
  }
}

const mapRange = (
  value: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number
) => ((value - x1) * (y2 - x2)) / (y1 - x1) + x2

const [MIN_RANGE, MAX_RANGE] = [1000 * 60 * 2, 1000 * 60 * 60 * 24 * 547]

const App = () => {
  const [scale$, setScale] = useReactive([
    Date.now() - 12 * 60 * 60 * 1000,
    Date.now()
  ])
  const [fetchArchiveperiods, { data }] = useLazyQuery<
    ArchivePeriods,
    ArchivePeriodsVariables
  >(ARCHIVE_PERIODS_QUERY)
  const [live$, setLive] = useReactive(true)
  const [cursor, setCursor] = useState<number | null>(null)
  const [hover, setHover] = useState(false)
  const hold = useRef(false)
  const timeline = useRef<HTMLDivElement>(null)
  const canvas = useRef<HTMLCanvasElement>(null)
  const { play, pause, playback, playingCamera } = usePlayback()
  const { dataChannel$ } = useStreamer()
  const [currentFrame$, setCurrentFrame] = useReactive<number | null>(null)
  const [latestFrame$, setLatestFrame] = useReactive<number | null>(null)
  const [periodsScale$, setPeriodsScale] = useReactive<
    { start: number; end: number }[] | null
  >(null)
  const { theme } = useTheme()

  useEffect(() => {
    if (playingCamera?.id) {
      fetchArchiveperiods({ variables: { cameraId: playingCamera?.id } })
    }
  }, [fetchArchiveperiods, playingCamera?.id])

  useEffect(() => {
    if (dataChannel$) {
      const subscriber = combineLatest([dataChannel$, live$]).subscribe(
        ([{ data }, live]) => {
          if (data.eventType === 'frame') {
            setCurrentFrame(data.timestampInMilliseconds)
            setLatestFrame((latestFrame) =>
              Math.max(data.timestampInMilliseconds, latestFrame || 0)
            )
            if (live) {
              setScale(([start, end]) => {
                const range = end - start
                return [
                  data.timestampInMilliseconds - range,
                  data.timestampInMilliseconds
                ]
              })
            }
          }
        }
      )
      return () => subscriber?.unsubscribe()
    }
  }, [dataChannel$, live$, setCurrentFrame, setLatestFrame, setScale])
  const onPanStart = (
    _e: MouseEvent | TouchEvent | PointerEvent,
    _info: PanInfo
  ) => {
    hold.current = true
  }
  const onPanEnd = (
    _e: MouseEvent | TouchEvent | PointerEvent,
    _info: PanInfo
  ) => {
    const id = setTimeout(() => {
      hold.current = false
    }, 300)
    return () => clearInterval(id)
  }
  const onPan = useCallback(
    (_e: MouseEvent | TouchEvent | PointerEvent, { delta: { x } }: PanInfo) => {
      const width = canvas.current?.width
      if (width) {
        setLive(false)
        setScale(([start, end]) => {
          const diff = end - start
          const toAddLeft = -x * (diff / width)
          const toAddRight = -x * (diff / width)
          const newStart = start + toAddLeft
          const newEnd = end + toAddRight

          return [newStart, newEnd]
        })
      }
    },
    [setLive, setScale]
  )
  const onWheel = useCallback(
    (e: React.WheelEvent<HTMLDivElement>) => {
      setScale(([start, end]) => {
        if (cursor) {
          const diff = end - start
          const ratio = live$.getValue() ? 1 : Math.abs((cursor - start) / diff)
          const toAddLeft = e.deltaY * ratio * (diff / 1000)
          const toAddRight = -e.deltaY * (1 - ratio) * (diff / 1000)
          const [newStart, newEnd] = [start + toAddLeft, end + toAddRight]
          const newRange = newEnd - newStart
          const shouldUpdate = newRange > MIN_RANGE && newRange < MAX_RANGE
          return shouldUpdate ? [newStart, newEnd] : [start, end]
        } else {
          return [start, end]
        }
      })
    },
    [cursor, live$, setScale]
  )
  const onMouseMove = useCallback(
    ({
      clientX,
      currentTarget
    }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const width = canvas.current?.width
      if (width) {
        const bounds = currentTarget.getBoundingClientRect()
        const left = clientX - bounds.left
        const ratio = left / width
        const [start, end] = scale$.getValue()
        const diff = end - start
        const timestamp = Math.round(start + diff * ratio)
        setCursor(timestamp)
      }
    },
    [scale$]
  )
  const onMouseLeave = useCallback(
    (_e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setCursor(null)
    },
    []
  )
  const onTap = useCallback(
    (_e: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
      if (!hold.current) {
        const valid = periodsScale$
          .getValue()
          ?.find(
            (period) =>
              info.point.x >= period.start && info.point.x <= period.end
          )
        if (valid && cursor) {
          setLive(false)
          play({ timestampInMilliseconds: cursor })
        }
      }
    },
    [cursor, periodsScale$, play, setLive]
  )

  /* INIT CANVAS WIDTH */
  useLayoutEffect(() => {
    if (canvas.current && timeline.current) {
      const { width } = getComputedStyle(timeline.current)
      canvas.current.setAttribute('width', width)
    }
  }, [])

  /* REBUILD VIDEO PERIODS */
  useLayoutEffect(() => {
    const archivePeriods = data?.archivePeriods
    const axisWidth = canvas.current?.width
    if (archivePeriods && axisWidth) {
      const subscriber = combineLatest([scale$, latestFrame$]).subscribe(
        ([scale, latestFrame]) => {
          setPeriodsScale(
            archivePeriods.map((period) => ({
              start: mapRange(
                period.timestampMs,
                scale[0],
                scale[1],
                0,
                axisWidth
              ),
              end: mapRange(
                period.durationMs > -1
                  ? period.timestampMs + period.durationMs
                  : latestFrame || 0,
                scale[0],
                scale[1],
                0,
                axisWidth
              )
            }))
          )
        }
      )

      return () => subscriber.unsubscribe()
    }
  }, [data?.archivePeriods, latestFrame$, scale$, setPeriodsScale])

  useLayoutEffect(() => {
    const subscriber = combineLatest([
      scale$,
      periodsScale$,
      currentFrame$,
      latestFrame$
    ]).subscribe(([scale, periodsScale, currentFrame, latestFrame]) => {
      if (canvas.current) {
        const time = scaleTime().domain(scale).range([0, canvas.current.width])
        const ticks = time.ticks()
        const timeFormat = time.tickFormat()
        const ctx = canvas.current.getContext('2d')
        ctx?.clearRect(0, 0, canvas.current.width, canvas.current.height)

        if (periodsScale) {
          for (const { start, end } of periodsScale) {
            ctx!.fillStyle = theme.color.gray5
            if (ctx) {
              const width = end - start
              const radius = width <= 40 ? width / 2 : 20
              roundRect(ctx, start, 0, width, 40, radius)
            }
          }
        }

        for (const tick of ticks) {
          const position = mapRange(
            +tick,
            scale[0],
            scale[1],
            0,
            canvas.current.width
          )
          ctx!.fillStyle = theme.color.gray
          ctx?.fillRect(position, 0, 2, 40)
          ctx?.fillText(timeFormat(tick), position + 1 + 4, 40 - 4)
        }
        if (cursor) {
          ctx!.fillStyle = theme.color.gray3
          ctx?.fillRect(
            mapRange(cursor, scale[0], scale[1], 0, canvas.current.width),
            0,
            2,
            40
          )
        }
        if (currentFrame) {
          ctx!.fillStyle = theme.color.red
          ctx?.fillRect(
            mapRange(currentFrame, scale[0], scale[1], 0, canvas.current.width),
            0,
            2,
            40
          )
        }
      }
    })

    return () => subscriber.unsubscribe()
  }, [
    currentFrame$,
    cursor,
    data?.archivePeriods,
    latestFrame$,
    periodsScale$,
    scale$,
    theme.color.gray,
    theme.color.gray3,
    theme.color.gray5,
    theme.color.red
  ])

  return (
    <Wrapper
      initial={{ height: 40 }}
      animate={{ height: hover ? 120 : 40 }}
      transition={{ damping: false, duration: 0.2, delay: hover ? 0 : 0.2 }}
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
    >
      <TimelineWrapper
        ref={timeline}
        initial={{ height: 40 }}
        animate={{ height: hover ? 120 : 40 }}
        transition={{ damping: false, duration: 0.2, delay: hover ? 0 : 0.2 }}
        onPan={onPan}
        onPanStart={onPanStart}
        onPanEnd={onPanEnd}
        onWheel={onWheel}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onTap={onTap}
      >
        <TimeAxis ref={canvas} height={40} />
      </TimelineWrapper>

      <Controls
        initial={{ height: 40 }}
        animate={{
          height: hover ? 120 : 40,
          transition: {
            damping: false,
            duration: 0.2,
            delay: hover ? 0 : 0.2
          }
        }}
      >
        <AnimatePresence>
          {hover && (
            <motion.i
              key="film"
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{
                opacity: [0, 1],
                transition: {
                  duration: 0.2,
                  delay: 0.2
                }
              }}
              className="fas fa-film"
            />
          )}
          {hover && (
            <motion.i
              key="step-forward"
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{
                opacity: [0, 1],
                transition: {
                  duration: 0.2,
                  delay: 0.2
                }
              }}
              className="fas fa-step-forward"
              onClick={() => {
                setLive(true)
                play({ timestampInMilliseconds: 0 })
              }}
            />
          )}
        </AnimatePresence>
        <i
          key="l"
          className={
            playback === 'playing'
              ? 'fas fa-pause'
              : playback === 'pending'
              ? 'fas fa-circle-notch'
              : 'fas fa-play'
          }
          onClick={() => (playback === 'playing' ? pause() : play())}
        />
      </Controls>
    </Wrapper>
  )
}

const Wrapper = styled(motion.div)`
  position: absolute;
  left: ${({ theme }) => theme.space.normal};
  right: ${({ theme }) => theme.space.normal};
  bottom: ${({ theme }) => theme.space.normal};
  z-index: 20;
`

const TimelineWrapper = styled(motion.div)`
  position: absolute;
  left: 56px;
  right: 56px;
  bottom: 0;
  border-radius: 20px;
  overflow: hidden;
  user-select: none;
  background: ${({ theme }) => `${theme.color.gray4}`};
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

  * {
    user-select: none;
  }
`

const TimeAxis = styled(motion.canvas)`
  position: absolute;
  bottom: 0;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
`
const Controls = styled(motion.div)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => `${theme.color.gray5}`};
  border-radius: 20px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

  i {
    position: absolute;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.fa-film {
      bottom: 80px;
    }
    &.fa-step-forward {
      bottom: 40px;
    }

    &.fa-play,
    &.fa-pause {
      bottom: 0;
    }
    &.fa-circle-notch {
      animation: ${spin} 5s linear infinite;
      bottom: 0;
    }
  }
`

export default App
