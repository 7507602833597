import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'

import { Link } from 'lib/ui'

const Layers = () => {
  const [showEdit, setShowEdit] = useState(false)

  return (
    <Wrapper
      animate={{
        width: showEdit ? 200 : 160,
        transition: { duration: 0.2, damping: false }
      }}>
      <Link to="/">
        <Icon className="fas fa-layer-group" />
      </Link>

      <Link to="./tracking">
        <Icon className="fas fa-map-pin" />
      </Link>
      <Link to="./heatmap">
        <Icon className="fas fa-map" />
      </Link>
      <Link
        to="./marking"
        onMouseEnter={() => setShowEdit(true)}
        onMouseLeave={() => setShowEdit(false)}>
        <Icon className="icon-shape-polygon" />
      </Link>
      <AnimatePresence>
        {showEdit && (
          <Link
            to="./marking/edit"
            onMouseEnter={() => setShowEdit(true)}
            onMouseLeave={() => setShowEdit(false)}>
            <Icon
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
              className="fas fa-pencil-ruler"
            />
          </Link>
        )}
      </AnimatePresence>
    </Wrapper>
  )
}

const Wrapper = styled(motion.div)`
  z-index: 2;
  position: absolute;
  top: 16px;
  right: 262px;
  height: 40px;
  display: flex;
  flex-direction: row-reverse;
  background: ${({ theme }) => theme.color.gray4};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  overflow: hidden;
`

const Icon = styled(motion.i)<{ active?: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.color.gray5};
  cursor: pointer;
  transition: background 0.2s;

  &.icon-shape-polygon {
    border-radius: 20px 0 0 20px;
  }

  &.fa-pencil-ruler {
    background: ${({ theme, active }) => theme.color.gray4};
  }
`

export default Layers
