import { useState, useMemo } from 'react'
import { DefaultTheme } from 'styled-components'

import { Marking } from 'lib/ui'

const Map = () => {
  const [markings] = useState<
    {
      id: string
      type: 'area' | 'line'
      name: string
      category: string
      color: keyof DefaultTheme['color']
      points: [x: number, y: number, z: number][]
    }[]
  >([
    {
      id: '1',
      name: 'new area',
      type: 'area',
      category: 'none',
      color: 'white',
      points: [
        [0.9401082530337657, -0.2343586039828252, -0.24753286108519534],
        [0.6232005754336741, -0.7775736338129152, -0.08366771646289808],
        [0.7530553500364049, -0.2972194076445688, 0.5869993726580629]
      ]
    },
    {
      id: '2',
      name: 'new line',
      type: 'line',
      category: 'none',
      color: 'white',
      points: [
        [0.9401082530337657, -0.2343586039828252, -0.24753286108519534],
        [0.6232005754336741, -0.7775736338129152, -0.08366771646289808]
      ]
    }
  ])

  const [polygons, lines] = useMemo(() => {
    const polygons = markings.filter(({ type }) => type === 'area')
    const lines = markings.filter(({ type }) => type === 'line')
    return [polygons, lines]
  }, [markings])

  return (
    <>
      <group>
        {polygons.map((marking) => (
          <Marking.Polygon
            id={marking.id}
            key={marking.id}
            points={marking.points}
            color={marking.color}
          />
        ))}
        {lines.map((marking) => (
          <Marking.Line
            id={marking.id}
            key={marking.id}
            points={marking.points}
            color={marking.color}
          />
        ))}
      </group>
    </>
  )
}

export { default as Edit } from '../edit'
export default Map
