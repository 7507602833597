import { useState, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import styled, { keyframes } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

import { usePlayback } from 'lib/streamer'
import { CAMERAS_LIST, Camera } from 'lib/graphql'
import { chunkArray } from 'lib/utils'

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 250 : -250,
      opacity: 0
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 250 : -250,
      opacity: 0
    }
  }
}

const Content = () => {
  const { data } = useQuery<{ cameras: Camera[] }>(CAMERAS_LIST)
  const [[page, direction], setPage] = useState([0, 0])
  const pages = useMemo(
    () => (data?.cameras ? chunkArray(data.cameras, 5) : []),
    [data?.cameras]
  )
  const { play, playingCamera, playback } = usePlayback()
  const [clickedCamera, setClickedCamera] = useState<string | null>(null)

  return (
    <Wrapper>
      <Section>
        <Head>
          <Title>Cameras</Title>
          <PaginationButtons>
            <PaginationButton
              disabled={page === 0}
              className="fas fa-caret-left"
              onClick={() => setPage(([page]) => [page - 1, -1])}
            />
            <PaginationButton
              disabled={page === pages.length - 1}
              className="fas fa-caret-right"
              onClick={() => setPage(([page]) => [page + 1, +1])}
            />
          </PaginationButtons>
        </Head>
        <ListWrapper>
          <AnimatePresence initial={false} custom={direction}>
            <List
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 }
              }}
            >
              {pages.length &&
                pages[page].map(({ id, name, isActive }) => (
                  <Item
                    key={id}
                    active={isActive}
                    selected={(clickedCamera || playingCamera?.id) === id}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => {
                      if (playingCamera?.id !== id) {
                        localStorage.setItem('cameraID', id)
                        setClickedCamera(id)
                        play({ cameraId: id, timestampInMilliseconds: 0 })
                      }
                    }}
                  >
                    <i
                      className={
                        id === clickedCamera &&
                        playingCamera !== id &&
                        playback === 'pending'
                          ? 'fas fa-circle-notch'
                          : 'fas fa-video'
                      }
                    />
                    <span>{name.replace(/\D/g, '')}</span>
                  </Item>
                ))}
            </List>
          </AnimatePresence>
        </ListWrapper>
      </Section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 40px;
  padding: ${({ theme }) => theme.space.normal};
`

const Section = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space.small};
  }
`
const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Title = styled.p`
  font-weight: bold;
`
const PaginationButtons = styled.div`
  display: flex;
  & > :not(:last-child) {
    margin-right: ${({ theme }) => theme.space.small};
  }
`
const PaginationButton = styled.i<{ disabled?: boolean }>`
  visibility: ${({ disabled }) => (disabled ? 'hidden' : 'initial')};
  cursor: pointer;
`
const ListWrapper = styled.div`
  position: relative;
  height: 60px;
`
const List = styled(motion.ul)`
  position: absolute;
  width: 100%;

  display: flex;
  list-style: none;
  overflow: hidden;

  & > :not(:last-child) {
    margin-right: ${({ theme }) => theme.space.small};
  }
`
const spin = keyframes`
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
`
const Item = styled(motion.li)<{ active: boolean; selected: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  flex: 1;
  background: ${({ theme }) => theme.color.gray5};
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  padding: ${({ theme }) => theme.space.normal};
  border-radius: 20px;
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
  transition: opacity 0.25s;

  &:hover {
    opacity: 1;
  }

  .fa-video {
    color: ${({ active }) => (active ? 'green' : 'red')};
    margin-right: ${({ theme }) => theme.space.small};
  }
  .fa-circle-notch {
    margin-right: ${({ theme }) => theme.space.small};
    animation: ${spin} 5s linear infinite;
  }
`

export default Content
