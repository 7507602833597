import type { Dark } from 'styled-components'

const dark: Dark = {
  white: '#f2f2f7',
  black: '#1c1c1e',

  gray: '#f2f2f7',
  gray1: '#8e8e93',
  gray2: '#636366',
  gray3: '#48484a',
  gray4: '#3a3a3c',
  gray5: '#2c2c2e',
  gray6: '#1c1c1e',

  blue: '#0a84ff',
  green: '#30d158',
  indigo: '#5e5ce6',
  orange: '#ff9f0a',
  pink: '#ff375f',
  purple: '#bf5af2',
  red: '#ff453a',
  teal: '#64d2ff',
  yellow: '#ffd60a'
}

export default dark
