import { useState } from 'react'
import { Doughnut, Line, Radar } from 'react-chartjs-2'
import styled from 'styled-components'

import { heatmap } from 'lib/assets'

// For demo purposes
const getRandom = (min: number, max: number) => {
  return ~~(Math.random() * (max - min + 1)) + min
}

const Graphs = () => {
  const [showMore, setShowMore] = useState(false)

  return (
    <Wrapper>
      <div style={{ gridArea: 'doughnut' }}>
        <Doughnut
          data={{
            labels: [
              'Standing up in stall',
              'Standing up in corridor',
              'Milking',
              'Feeding',
              'Laying Down',
              'Ruminating'
            ],
            datasets: [
              {
                data: [2.7, 2.5, 2.7, 4.2, 7, 4.9],
                backgroundColor: [
                  '#FF6384',
                  '#36A2EB',
                  '#9575cd',
                  '#4caf50',
                  '#fbc02d',
                  '#f57c00'
                ]
              }
            ]
          }}
          options={{ aspectRatio: 1, maintainAspectRatio: true }}
          height={100}
        />
      </div>
      <div style={{ gridArea: 'radar' }}>
        <Radar
          data={{
            labels: [
              'Eating',
              'Drinking',
              'Sleeping',
              'Designing',
              'Coding',
              'Cycling',
              'Running',
              'Runningg'
            ],
            datasets: [
              {
                label: 'My First dataset',
                backgroundColor: 'rgba(179,181,198,0.2)',
                borderColor: 'rgba(179,181,198,1)',
                pointBackgroundColor: 'rgba(179,181,198,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(179,181,198,1)',
                data: [65, 59, 90, 81, 56, 55, 40, 89]
              },
              {
                label: 'My Second dataset',
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                pointBackgroundColor: 'rgba(255,99,132,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(255,99,132,1)',
                data: [28, 48, 40, 19, 96, 27, 100, 89]
              }
            ]
          }}
          options={{ aspectRatio: 1, maintainAspectRatio: true }}
          height={100}
        />
      </div>
      <div style={{ gridArea: 'line' }}>
        <Line
          data={{
            labels: new Array(500).fill(null).map((_, index) => index),
            datasets: [
              {
                label: 'My First dataset',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: [
                  ...new Array(490).fill(null).map(() => getRandom(10, 30)),
                  ...new Array(10).fill(null).map(() => getRandom(30, 100))
                ]
              }
            ]
          }}
          options={{ aspectRatio: 2, maintainAspectRatio: true }}
          height={100}
        />
      </div>
      {showMore && (
        <>
          <div style={{ gridArea: 'heatmap' }}>
            <Image src={heatmap} />
          </div>
          <div style={{ gridArea: 'stacked' }}>
            <Line
              data={{
                labels: [
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July'
                ],
                datasets: [
                  {
                    label: 'Standing up in stall',
                    backgroundColor: '#FF6384',
                    borderWidth: 1,
                    data: [11, 13, 9, 11, 10, 12, 11]
                  },
                  {
                    label: 'Standing up in corridor',
                    backgroundColor: '#36A2EB',
                    borderWidth: 1,
                    data: [21, 23, 22, 20, 19, 21, 22]
                  },
                  {
                    label: 'Milking',
                    backgroundColor: '#9575cd',
                    borderWidth: 1,
                    data: [32, 34, 37, 30, 28, 32, 40]
                  },
                  {
                    label: 'Feeding',
                    backgroundColor: '#4caf50',
                    borderWidth: 1,
                    data: [50, 45, 48, 55, 40, 41, 50]
                  },
                  {
                    label: 'Laying Down',
                    backgroundColor: '#fbc02d',
                    borderWidth: 1,
                    data: [79, 65, 70, 80, 70, 65, 79]
                  },
                  {
                    label: 'Ruminating',
                    backgroundColor: '#f57c00',
                    borderWidth: 1,
                    data: [100, 100, 100, 100, 100, 100, 100]
                  }
                ]
              }}
              options={{ aspectRatio: 1, maintainAspectRatio: true }}
              height={100}
            />
          </div>
        </>
      )}
      <ShowMore onClick={() => setShowMore((showMore) => !showMore)}>
        {showMore ? 'Show Less' : 'Show More'}
      </ShowMore>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'doughnut radar'
    'line line'
    'heatmap stacked';
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.normal};

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${({ theme }) => theme.space.normal};
    border-radius: 20px;
  }
`

const Image = styled.img.attrs({ alt: 'heatmap' })`
  max-width: 90%;
  border-radius: 20px;
`

const ShowMore = styled.p`
  cursor: pointer;
`

export default Graphs
