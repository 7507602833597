import { FC, memo, useMemo, useState, useEffect } from 'react'
import { LinearFilter, Texture } from 'three'
import { useThree } from 'react-three-fiber'
import { Line as Line2 } from '@react-three/drei'
import { DefaultTheme } from 'styled-components'

import { useTheme } from 'lib/ui'

import ResizablePoints from './resizable-points'

interface Props {
  id: string
  points: [x: number, y: number, z: number][]
  color?: keyof DefaultTheme['color']
  selected?: boolean
  onSelect?: (id: string) => any
  onChanged?: (id: string, points: Props['points']) => any
}
const Line: FC<Props> = ({
  id,
  points: intialPoints,
  color = 'gray6',
  selected = false,
  onSelect,
  onChanged
}) => {
  const [points, setPoints] = useState(intialPoints || [])
  const { theme } = useTheme()

  const { gl } = useThree()

  useEffect(() => {
    if (JSON.stringify(intialPoints) !== JSON.stringify(points)) {
      onChanged?.(id, points)
    }
  }, [id, intialPoints, onChanged, points])

  const dotsTexture = useMemo(() => {
    const canvas = document.createElement('canvas')
    canvas.width = canvas.height = 40
    const ctx = canvas.getContext('2d')
    const texture = new Texture(canvas)
    const center = 40 / 2
    ctx?.beginPath()
    ctx?.arc(center, center, 40 / 2, 0, 2 * Math.PI, false)
    ctx?.closePath()
    ctx!.fillStyle = theme.color[color]
    ctx?.fill()
    texture.anisotropy = gl.capabilities.getMaxAnisotropy()
    texture.minFilter = LinearFilter
    texture.needsUpdate = true
    return texture
  }, [color, gl.capabilities, theme.color])

  return (
    <group renderOrder={1}>
      {points.length && (
        <Line2
          points={
            points.map((point) => point.map((coord) => coord * 10_000)) as any
          }
          onPointerUp={() => onSelect?.(id)}
          transparent
          opacity={0.5}
          lineWidth={2}
          color={theme.color[color]}
          vertexColors={points.map(() => [1, 1, 1])}
          flatShading={false}
        />
      )}

      {selected && (
        <ResizablePoints
          points={points}
          setPoints={setPoints}
          texture={dotsTexture}
          preventClosing
        />
      )}
    </group>
  )
}

export default memo(Line)
