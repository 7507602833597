import { Routes, Route, Navigate } from 'react-router'

import VideoWrapper from 'lib/video'
import { PagesMenu } from 'lib/ui'

import * as Video from './video'
import * as Herd from './herd'

const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/video/tracking" />} />

      <Route path="video/*" element={<VideoWrapper />}>
        <Route path="tracking" element={<Video.Tracking />} />
        <Route path="marking" element={<Video.Marking.Root />} />
        <Route path="marking/edit" element={<Video.Marking.Edit />} />
      </Route>

      <Route
        path="herd/*"
        element={
          <PagesMenu
            sidebar={{
              title: 'Herd',
              links: [
                { label: 'Animals', to: 'animals' },
                { label: 'Rules', to: 'rules' },
                { label: 'Map', to: 'map' },
                { label: 'Farm Robots', to: 'farm-robots' },
                { label: 'Machinery', to: 'machinery' }
              ]
            }}
            header={[
              { text: 'Animals', path: 'animals' },
              { text: 'Animal #{id}', path: 'animals/:id', back: 'animals' },
              { text: 'Rules', path: 'rules' },
              { text: 'New rule', path: 'rules/new', back: 'rules' },
              { text: 'Map', path: 'map' },
              { text: 'Farm Robots', path: 'farm-robots' },
              { text: 'Machinery', path: 'machinery' }
            ]}
          />
        }
      >
        <Route path="" element={<Navigate to="animals" />} />
        <Route path="animals">
          <Route path="" element={<Herd.Root />} />
          <Route path=":id" element={<Herd.Animal />} />
        </Route>

        <Route path="rules">
          <Route path="" element={<Herd.Rules.Root />} />
          <Route path="new" element={<Herd.Rules.New />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default Pages
