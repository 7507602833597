import { useEffect, useRef, useCallback, useMemo } from 'react'
import * as Comlink from 'comlink'
import { map } from 'rxjs/operators'
import { Vector3 } from 'three'

/* eslint-disable import/no-webpack-loader-syntax */
import Worker from 'worker-loader!../worker'
import { PHYSICAL_ENTITIES_QUERY } from '../graphql'
import { useStreamer } from '../streamer'

const FETCH_INTERVAL = 2.5 * 1000
const FETCH_UP_TO = 5 * 1000

const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT

const workerInstance = new Worker()
const { fetchTrackingPositions } = Comlink.wrap<WorkerObject>(workerInstance)

const useTracking = () => {
  const camera = useRef<string | null>(null)
  const cows = useRef<Map<number, string[]> | null>(null)
  const positions = useRef<Map<
    string,
    {
      timestamp: number
      coords: [number, number, number]
    }[]
  > | null>(null)
  const { dataChannel$ } = useStreamer()

  const lastFetchedTimestamp = useRef<number | null>(null)
  const queryStatus = useRef<'ready' | 'loading' | 'error'>('ready')

  const points$ = useMemo(() => {
    return dataChannel$?.pipe(
      map(({ data: { timestampInMilliseconds } }) => {
        if (cows.current instanceof Map && positions.current instanceof Map) {
          const availableTimestamps = [...cows.current.keys()].sort(
            (a, b) => a - b
          )

          const currentClosestAvailableTimestamp = availableTimestamps.reduce(
            (prev, curr) =>
              Math.abs(curr - timestampInMilliseconds) <
              Math.abs(prev - timestampInMilliseconds)
                ? curr
                : prev,
            0
          )

          const currentAnimals =
            cows.current.get(currentClosestAvailableTimestamp) || []
          const currentPoints: { [animal: string]: Vector3 } = {}

          for (const animal of currentAnimals) {
            const point = positions.current
              .get(animal)
              ?.find(
                ({ timestamp }) =>
                  timestamp === currentClosestAvailableTimestamp
              )?.coords
            if (point) {
              currentPoints[animal] = new Vector3(...point).multiplyScalar(
                10_000
              )
            }
          }

          const nextAvailableTimestamp =
            availableTimestamps[
              availableTimestamps.indexOf(currentClosestAvailableTimestamp) + 1
            ]

          const nextAnimals = cows.current.get(nextAvailableTimestamp) || []
          const nextPoints: { [animal: string]: Vector3 } = {}

          for (const animal of nextAnimals) {
            const point = positions.current
              .get(animal)
              ?.find(({ timestamp }) => timestamp === nextAvailableTimestamp)
              ?.coords
            if (point) {
              nextPoints[animal] = new Vector3(...point).multiplyScalar(10_000)
            }
          }

          const averageDeltaTime =
            nextAvailableTimestamp - currentClosestAvailableTimestamp

          return {
            currentPoints,
            nextPoints,
            averageDeltaTime
          }
        } else {
          return {
            currentPoints: {},
            nextPoints: {},
            averageDeltaTime: 0
          }
        }
      })
    )
  }, [dataChannel$])

  const makeQuery = useCallback(
    async (cameraId: string, start: number, end: number) => {
      queryStatus.current = 'loading'
      const queryString = PHYSICAL_ENTITIES_QUERY.loc?.source.body
      if (!queryString) throw Error('Missing query string')
      if (!graphqlEndpoint) throw Error('Missing graphql endpoint')

      const { physicalEntities } = await fetchTrackingPositions(
        graphqlEndpoint,
        queryString,
        {
          cameraId,
          start,
          end,
          minIntervalMs: 1200
        }
      )
      if (physicalEntities?.cows && physicalEntities?.positions) {
        cows.current = new Map(
          physicalEntities.cows.map(({ timestamp, ids }) => [timestamp, ids])
        )

        positions.current = new Map(
          physicalEntities.positions.map(({ cowId, coordsAtTimestamp }) => [
            cowId,
            coordsAtTimestamp
          ])
        )

        lastFetchedTimestamp.current = Math.max(
          ...physicalEntities.cows.map(({ timestamp }) => timestamp)
        )
        queryStatus.current = 'ready'
      }
    },
    []
  )

  useEffect(() => {
    const subscriber = dataChannel$?.subscribe(({ data }) => {
      if (camera.current !== data.cameraId) {
        lastFetchedTimestamp.current = null
        camera.current = data.cameraId
      }
      if (
        (data.eventType === 'paused' ||
          data.eventType === 'ended' ||
          lastFetchedTimestamp.current === null ||
          data.timestampInMilliseconds >=
            lastFetchedTimestamp.current - FETCH_INTERVAL) &&
        queryStatus.current === 'ready'
      ) {
        makeQuery(
          data.cameraId,
          data.timestampInMilliseconds,
          data.timestampInMilliseconds + FETCH_UP_TO
        )
      }
    })
    return () => subscriber?.unsubscribe()
  }, [dataChannel$, makeQuery])

  return { points$ }
}

export default useTracking
