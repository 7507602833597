import { forwardRef } from 'react'
import {
  useNavigate,
  useHref,
  useLocation,
  useResolvedPath
} from 'react-router'
import { State, To, createPath } from 'history'

import { isModifiedEvent } from 'lib/utils'

interface LinkProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  replace?: boolean
  state?: State
  to: To
}
const Link = forwardRef<HTMLAnchorElement, LinkProps>(function LinkWithRef(
  {
    onClick,
    replace: replaceProp = false,
    state,
    target,
    to,
    children,
    ...rest
  },
  ref
) {
  let href = useHref(to)
  let navigate = useNavigate()
  let location = useLocation()
  let path = useResolvedPath(to)

  function handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
    if (onClick) onClick(event)
    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // Ignore everything but left clicks
      (!target || target === '_self') && // Let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // Ignore clicks with modifier keys
    ) {
      event.preventDefault()

      // If the URL hasn't changed, a regular <a> will do a replace instead of
      // a push, so do the same here.
      let replace = !!replaceProp || createPath(location) === createPath(path)

      navigate(to, { replace, state })
    }
  }

  return (
    <a
      {...rest}
      href={href}
      onClick={handleClick}
      ref={ref}
      target={target}
      rel="noreferrer">
      {children}
    </a>
  )
})

export interface NavLinkProps extends LinkProps {
  activeClassName?: string
  activeStyle?: object
  caseSensitive?: boolean
  end?: boolean
}

/**
 * A <Link> wrapper that knows if it's "active" or not.
 */
export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  function NavLinkWithRef(
    {
      'aria-current': ariaCurrentProp = 'page',
      activeClassName = 'active',
      activeStyle,
      caseSensitive = false,
      className: classNameProp = '',
      end = false,
      style: styleProp,
      to,
      ...rest
    },
    ref
  ) {
    let location = useLocation()
    let path = useResolvedPath(to)

    let locationPathname = location.pathname
    let toPathname = path.pathname
    if (!caseSensitive) {
      locationPathname = locationPathname.toLowerCase()
      toPathname = toPathname.toLowerCase()
    }

    let isActive = end
      ? locationPathname === toPathname
      : locationPathname.startsWith(toPathname)

    let ariaCurrent = isActive ? ariaCurrentProp : undefined
    let className = [classNameProp, isActive ? activeClassName : null]
      .filter(Boolean)
      .join(' ')
    let style = { ...styleProp, ...(isActive ? activeStyle : null) }

    return (
      <Link
        {...rest}
        aria-current={ariaCurrent}
        className={className}
        ref={ref}
        style={style}
        to={to}
      />
    )
  }
)

export default NavLink
