import { forwardRef, ReactNode } from 'react'
import styled, { DefaultTheme } from 'styled-components'

interface Props {
  id?: string
  name?: string
  value?: string
  color?: keyof DefaultTheme['color']
  children?: ReactNode
}
const Pill = forwardRef<HTMLInputElement, Props>(
  ({ id, name, value, color, children }, ref) => {
    return (
      <>
        <Input
          type="radio"
          ref={ref}
          name={name}
          id={id}
          value={value}
          labelColor={color}
        />
        <label htmlFor={id}>{children}</label>
      </>
    )
  }
)

const Input = styled.input<{ labelColor?: string }>`
  display: none;

  + label {
    min-width: 40px;
    padding: ${({ theme }) => `${theme.space.small} ${theme.space.normal}`};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: ${({ theme }) => theme.color.gray5};
    transition: 0.25s background;
    cursor: pointer;

    & > :not(:last-child) {
      margin-right: ${({ theme }) => theme.space.small};
    }

    &:hover {
      background: ${({ theme }) => theme.color.gray4};
    }
  }

  &:checked + label {
    background: ${({ theme }) => theme.color.gray4};
  }
`

export default Pill
