import { FC } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { useHistory } from 'lib/history'
import { ThreeHtml } from 'lib/ui'

interface Props {
  onAdd: (type: 'area' | 'line') => any
  showOptions: boolean
  onEdit: () => any
  onDelete: () => any
  onSave: () => any
}
const Sidebar: FC<Props> = ({
  onAdd,
  showOptions,
  onEdit,
  onDelete,
  onSave
}) => {
  const { history } = useHistory()

  return (
    <>
      <ThreeHtml fullscreen style={{ pointerEvents: 'none' }}>
        <Wrapper initial={{ x: 56, y: '-50%' }} animate={{ x: 0, y: '-50%' }}>
          <BaseTools>
            <Tool
              className="fas fa-times"
              onClick={() => history.push('/video/marking')}
            />
            <Tool className="fas fa-save" onClick={onSave} />
            <Tool onClick={() => onAdd('area')} className="icon-shape-square" />
            <Tool onClick={() => onAdd('line')} className="icon-shape-line" />
          </BaseTools>

          <SelectedTools
            initial={{ height: 0 }}
            animate={{
              height: showOptions ? 80 : 0,
              transition: { duration: 0.2, damping: false }
            }}>
            <Tool
              initial={{ opacity: 0 }}
              animate={{
                opacity: showOptions ? 1 : 0,
                transition: { delay: 0.2 }
              }}
              className="fas fa-edit"
              onClick={onEdit}
            />
            <Tool
              initial={{ opacity: 0 }}
              animate={{
                opacity: showOptions ? 1 : 0,
                transition: { delay: 0.2 }
              }}
              className="fas fa-trash-alt"
              onClick={onDelete}
            />
          </SelectedTools>
        </Wrapper>
      </ThreeHtml>
    </>
  )
}

const Wrapper = styled(motion.div)`
  z-index: 2;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.gray4};
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  pointer-events: all;
`

const BaseTools = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.gray5};
  border-radius: ${({ theme }) => theme.borderRadius};
`

const SelectedTools = styled(motion.div)`
  display: flex;
  flex-direction: column;
`

const Tool = styled(motion.i)<{ disabled?: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

export default Sidebar
