const chunkArray = (array: any[], size: number) => {
  if (size <= 0) throw Error('Invalid chunk size')
  let result = []
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size))
  }
  return result
}

export default chunkArray
