import styled from 'styled-components'

import { logo } from 'lib/assets'

const Logo = styled.img.attrs({ src: logo, alt: 'AIHERD' })`
  z-index: 1;
  position: absolute;
  top: ${({ theme }) => theme.space.normal};
  left: ${({ theme }) => theme.space.normal};
  height: 40px;
  fill: ${({ theme }) => theme.color.gray5};
`

export default Logo
