import { useRef, useLayoutEffect } from 'react'
import { extend, useThree } from 'react-three-fiber'
import { MathUtils } from 'three'
import { OrbitControls } from '@react-three/drei'
import { fromEvent } from 'rxjs'

extend({ OrbitControls })

const getMaxPolarAngle = (zoom: number, fov: number) =>
  Math.PI / 2 - Math.atan(Math.tan(MathUtils.DEG2RAD * 0.5 * fov) / zoom)

const Controls = () => {
  const ref = useRef<OrbitControls>()
  const {
    scene,
    camera,
    gl: { domElement }
  } = useThree()
  const fov = (camera.type === 'PerspectiveCamera' && camera.fov) || 80

  useLayoutEffect(() => {
    const subscriber = fromEvent(camera, 'zoom').subscribe(() => {
      ref.current!.maxPolarAngle = getMaxPolarAngle(camera.zoom, fov)
    })
    return () => subscriber.unsubscribe()
  }, [camera, fov])

  useLayoutEffect(() => {
    const subscriber = fromEvent(scene, 'controls').subscribe(({ enabled }) => {
      ref.current!.enabled = enabled
    })
    return () => subscriber.unsubscribe()
  }, [camera, fov, scene])

  return (
    <OrbitControls
      ref={ref}
      maxPolarAngle={getMaxPolarAngle(camera.zoom, fov)}
      rotateSpeed={-0.25}
      enableZoom={false}
      enablePan={false}
      domElement={domElement}
    />
  )
}

export default Controls
