import { useRef, useMemo } from 'react'
import {
  Mesh,
  BackSide,
  EquirectangularReflectionMapping,
  Euler,
  sRGBEncoding,
  Vector3
} from 'three'
import { Sphere } from '@react-three/drei'

import { useStreamer } from 'lib/streamer'

const Dome = () => {
  const ref = useRef<Mesh>()
  const { stream } = useStreamer()

  const video = useMemo(() => {
    const video = document.createElement('video')
    video.muted = true
    video.srcObject = stream
    video.play()
    return video
  }, [stream])

  return (
    <Sphere
      ref={ref}
      name="dome"
      args={[10_000, 60, 40, 0, Math.PI]}
      rotation={new Euler(Math.PI / 2, 0, 0)}
      position={new Vector3(0, 0, 0)}
      onPointerUp={(e) => {
        ref.current?.dispatchEvent(e)
      }}
      onPointerDown={(e) => {
        ref.current?.dispatchEvent(e)
      }}
      onPointerMove={(e) => ref.current?.dispatchEvent(e)}
      renderOrder={0}
    >
      <meshBasicMaterial depthTest={false} attach="material" side={BackSide}>
        <videoTexture
          args={[video, EquirectangularReflectionMapping]}
          attach="map"
          encoding={sRGBEncoding}
          flipY={false}
        />
      </meshBasicMaterial>
    </Sphere>
  )
}

export default Dome
