import { FC, memo, useMemo, useRef, useState, useEffect } from 'react'
import { Euler, LinearFilter, Mesh, Shape, Texture, Vector3 } from 'three'
import { useThree } from 'react-three-fiber'
import { DefaultTheme } from 'styled-components'

import { planeWorldConverter } from 'lib/utils'
import { useTheme } from 'lib/ui'

import ResizablePoints from './resizable-points'

interface Props {
  id: string
  points: [x: number, y: number, z: number][]
  color: keyof DefaultTheme['color']
  selected?: boolean
  onSelect?: (id: string) => any
  onChanged?: (id: string, points: Props['points']) => any
}
const Polygon: FC<Props> = ({
  id,
  points: intialPoints,
  color,
  selected = false,
  onSelect,
  onChanged
}) => {
  const polygonRef = useRef<Mesh>()

  const [points, setPoints] = useState(intialPoints || [])

  const { theme } = useTheme()
  const { gl } = useThree()

  useEffect(() => {
    if (JSON.stringify(intialPoints) !== JSON.stringify(points)) {
      onChanged?.(id, points)
    }
  }, [id, intialPoints, onChanged, points])

  const dotsTexture = useMemo(() => {
    const canvas = document.createElement('canvas')
    canvas.width = canvas.height = 40
    const ctx = canvas.getContext('2d')
    const texture = new Texture(canvas)
    const center = 40 / 2
    ctx?.beginPath()
    ctx?.arc(center, center, 40 / 2, 0, 2 * Math.PI, false)
    ctx?.closePath()
    ctx!.fillStyle = theme.color[color]
    ctx?.fill()
    texture.anisotropy = gl.capabilities.getMaxAnisotropy()
    texture.minFilter = LinearFilter
    texture.needsUpdate = true
    return texture
  }, [color, gl.capabilities, theme.color])

  return (
    <group renderOrder={1}>
      {points.length && (
        <mesh
          ref={polygonRef}
          position={new Vector3(0, -10_000, 0)}
          rotation={new Euler(-Math.PI / 2, 0, 0)}
          onPointerUp={() => onSelect?.(id)}
        >
          <shapeBufferGeometry
            attach="geometry"
            args={[
              [
                new Shape(
                  points.map((point) => planeWorldConverter('2d', point))
                )
              ]
            ]}
          />
          <meshBasicMaterial
            attach="material"
            transparent
            opacity={0.5}
            color={theme.color[color]}
            depthTest={false}
          />
        </mesh>
      )}

      {selected && (
        <ResizablePoints
          points={points}
          setPoints={setPoints}
          texture={dotsTexture}
        />
      )}
    </group>
  )
}

export default memo(Polygon)
