import { useCallback, useMemo, useState, useEffect } from 'react'
import { fromEvent } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

import { useStatus } from '../utils'

const RTC_CONFIGURATION: RTCConfiguration = {
  iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
}

const DATA_CHANNEL_LABEL = 'data'

const useWebRTC = () => {
  const [{ peer, dataChannel }, setInstances] = useState<{
    peer?: RTCPeerConnection
    dataChannel?: RTCDataChannel
  }>({})
  const dataChannelStatus = useStatus(dataChannel)
  const [peerStatus, setPeerStatus] = useState<
    'openned' | 'loading' | 'closed' | 'failed'
  >()

  useEffect(() => {
    if (peer) {
      const subscriber = fromEvent(peer, 'connectionstatechange')
        .pipe(
          map(() => {
            switch (peer.connectionState) {
              case 'connected':
                return 'openned'
              case 'connecting':
              case 'new':
                return 'loading'
              case 'disconnected':
              case 'closed':
                return 'closed'
              case 'failed':
                return 'failed'
              default:
                return 'closed'
            }
          }),
          startWith('loading' as const)
        )
        .subscribe(setPeerStatus)

      return () => subscriber.unsubscribe()
    }
  }, [peer])

  const iceCandidate$ = useMemo(() => {
    if (peer) return fromEvent<RTCPeerConnectionIceEvent>(peer, 'icecandidate')
  }, [peer])

  const track$ = useMemo(() => {
    if (peer) {
      return fromEvent<RTCTrackEvent>(peer, 'track')
    }
  }, [peer])

  const createOffer = useCallback(async () => {
    peer?.addTransceiver('video', { direction: 'sendrecv' })
    peer?.addTransceiver('audio', { direction: 'sendrecv' })
    const offer = await peer?.createOffer({ offerToReceiveAudio: false })
    if (offer) {
      await peer?.setLocalDescription(offer)
    }
  }, [peer])

  const setRemoteDescription = useCallback(
    async (e: MessageEvent<string>) => {
      if (peer?.signalingState === 'have-local-offer') {
        const message: Omit<RTCSessionDescription, 'toJSON'> = JSON.parse(
          e.data
        )
        const remoteDescription = new RTCSessionDescription(message)
        await peer.setRemoteDescription(remoteDescription)
      }
    },
    [peer]
  )

  const sendCommand = useCallback(
    (data: { commandType: string; commandOptions: {} }) => {
      if (dataChannel?.readyState === 'open') {
        try {
          const message = {
            messageType: 'command',
            data
          }
          dataChannel.send(JSON.stringify(message))
        } catch (err) {
          throw Error('unable to send command:' + err)
        }
      }
    },
    [dataChannel]
  )

  const dataChannel$ = useMemo(() => {
    if (dataChannel) {
      return fromEvent<MessageEvent>(dataChannel, 'message').pipe(
        map(
          (e) =>
            JSON.parse(e.data) as {
              messageType: string
              data: {
                cameraId: string
                eventType: 'frame' | 'playing' | 'paused' | 'ended'
                timestampInMilliseconds: number
              }
            }
        )
      )
    }
  }, [dataChannel])

  const init = useCallback(() => {
    const peer = new RTCPeerConnection(RTC_CONFIGURATION)
    const dataChannel = peer.createDataChannel(DATA_CHANNEL_LABEL)
    setInstances({ peer, dataChannel })
  }, [])

  useEffect(() => {
    if (peer && dataChannel) {
      createOffer()
    }
  }, [peer, dataChannel, createOffer])

  return {
    init,
    iceCandidate$,
    track$,
    setRemoteDescription,
    sendCommand,
    dataChannel$,
    dataChannelStatus,
    peerStatus
  }
}

export default useWebRTC
