import { FC, useMemo, useState } from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

import type { Animal } from '../../graphql'

const severityColorMap = {
  low: 'yellow',
  medium: 'orange',
  high: 'red'
} as const

const eventColorMap = {
  lameness: 'indigo',
  heat: 'pink',
  activity: 'blue',
  calving: 'green',
  weight: 'orange',
  overlap: 'teal',
  'misc.': 'gray2'
} as const

interface Props {
  notifications: Animal['notifications']
}
const Notifications: FC<Props> = ({ notifications }) => {
  const mostSevereNotifications = useMemo(() => {
    const mostRecentNotifications = [...notifications].sort(
      (a, b) => a.createdAt - b.createdAt
    )
    const notificationsSeverityOrder = { high: 1, medium: 2, low: 3 }

    return [...mostRecentNotifications].sort(
      (a, b) =>
        notificationsSeverityOrder[a.severity] -
        notificationsSeverityOrder[b.severity]
    )
  }, [notifications])

  const [displayed, setDisplayed] = useState(0)

  return (
    <Wrapper>
      {mostSevereNotifications.length ? (
        <>
          <AnimatePresence exitBeforeEnter>
            <Notification
              key={displayed}
              initial={{ opacity: 0 }}
              animate={{ opacity: [0, 1] }}
              exit={{ opacity: 0 }}>
              <div className="notification--head">
                <div className="notification--head--text">
                  <NotificationType
                    beforeColor={
                      eventColorMap[mostSevereNotifications[displayed].type]
                    }
                    afterColor={
                      severityColorMap[
                        mostSevereNotifications[displayed].severity
                      ]
                    }>
                    {mostSevereNotifications[displayed].type}
                  </NotificationType>
                  <p className="date">
                    {new Date(
                      mostSevereNotifications[displayed].createdAt
                    ).toLocaleString(undefined, {
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric'
                    })}
                  </p>
                </div>
                <CowIcon
                  className={`icon-cow-${mostSevereNotifications[displayed].type}`}
                />
              </div>
              <p>{mostSevereNotifications[displayed].description}</p>
            </Notification>
          </AnimatePresence>
          <Buttons>
            <i
              className="fas fa-arrow-up"
              onClick={() =>
                setDisplayed((displayed) =>
                  displayed === 0
                    ? mostSevereNotifications.length - 1
                    : displayed - 1
                )
              }
            />
            <i
              className="fas fa-arrow-down"
              onClick={() =>
                setDisplayed((displayed) =>
                  displayed === mostSevereNotifications.length - 1
                    ? 0
                    : displayed + 1
                )
              }
            />
          </Buttons>
          <BadgesWrapper>
            {!!mostSevereNotifications.length &&
              mostSevereNotifications.map(({ type }, index) => (
                <Badge
                  key={index}
                  color={eventColorMap[type]}
                  onClick={() => setDisplayed(index)}
                />
              ))}
          </BadgesWrapper>
        </>
      ) : (
        <p>Nothing special!</p>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0px;
  }
`

const Notification = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: visible;

  .notification--head {
    display: flex;
    align-items: flex-end;
    overflow: visible;
    margin-bottom: ${({ theme }) => theme.space.small};
  }
  .date {
    font-weight: bold;
  }
`

const CowIcon = styled.i`
  display: block;
  font-size: 56px;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => `0 ${theme.space.normal}`};

  i {
    cursor: pointer;
  }

  & > :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space.normal};
  }
`

const NotificationType = styled.p<{
  beforeColor: keyof DefaultTheme['color']
  afterColor: keyof DefaultTheme['color']
}>`
  font-weight: bold;
  text-transform: uppercase;

  &:before,
  &:after {
    display: inline-block;
  }

  &:before {
    content: '';
    background: ${({ theme, beforeColor }) => theme.color[beforeColor]};
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-right: ${({ theme }) => theme.space.small};
  }

  &:after {
    content: '\f102';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: top;
    font-size: ${({ theme }) => theme.font.size.small};
    color: ${({ theme, beforeColor }) => theme.color[beforeColor]};
    margin-left: ${({ theme }) => theme.space.small};
  }
`

const BadgesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space.small};
  }
`

const Badge = styled.div<{ color: keyof DefaultTheme['color'] }>`
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background: ${({ theme, color }) => theme.color[color]};
  cursor: pointer;
`

export default Notifications
