import { useContext } from 'react'

import PlaybackContext from './playback-context'

const usePlayback = () => {
  const { playback, playingCamera, play, pause } = useContext(PlaybackContext)

  return { playback, playingCamera, play, pause }
}

export default usePlayback
