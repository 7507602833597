import { useRef, useCallback } from 'react'
import { BehaviorSubject } from 'rxjs'

const useReactive = <S = any>(initialState: S) => {
  const behaviorSubject = useRef(new BehaviorSubject(initialState))
  const setValue = useCallback(
    (arg: ((currentState: S) => S) | S) => {
      if (arg instanceof Function) {
        const currentState = behaviorSubject.current.getValue()
        const nextState = arg(currentState)
        behaviorSubject.current.next(nextState)
      } else {
        behaviorSubject.current.next(arg)
      }
    },
    [behaviorSubject]
  )

  return [behaviorSubject.current, setValue] as const
}

export default useReactive
