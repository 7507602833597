import styled, { keyframes } from 'styled-components'

const spinAnimation = keyframes`
    from {
        transform: rotate(0deg)
    } to {
        transform: rotate(360deg)
    }
`

const Spinner = styled.i.attrs({ className: 'fad fa-spinner-third' })`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.gray5};
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${spinAnimation} 0.5s infinite linear;
`

export default Spinner
