import { useContext } from 'react'

import StreamerContext from './streamer-context'

const useStreamer = () => {
  const { status, stream, sendCommand, dataChannel$ } = useContext(
    StreamerContext
  )

  return { status, stream, sendCommand, dataChannel$ }
}

export default useStreamer
