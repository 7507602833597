import { useContext } from 'react'

import ThemeContext from './theme-context'

const useTheme = () => {
  const { theme, mode, toggleMode } = useContext(ThemeContext)

  return { theme, mode, toggleMode }
}

export default useTheme
