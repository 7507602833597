import { useState, useCallback, useMemo } from 'react'
import { fromEvent } from 'rxjs'

import { useStatus } from '../utils'

const WS_URI = process.env.REACT_APP_STREAMER_ENDPOINT

const useWebSocket = () => {
  const [webSocket, setWebSocket] = useState<WebSocket>()
  const status = useStatus(webSocket)

  const remoteDescription$ = useMemo(() => {
    if (webSocket) {
      return fromEvent<MessageEvent<string>>(webSocket, 'message')
    }
  }, [webSocket])

  const sendLocalDescription = useCallback(
    (e: RTCPeerConnectionIceEvent) => {
      if (webSocket) {
        if (!e.candidate && webSocket.readyState === webSocket.OPEN) {
          const payload = {
            messageType: 'sdp',
            data: (e.target as RTCPeerConnection).localDescription
          }
          webSocket.send(JSON.stringify(payload))
        }
      }
    },
    [webSocket]
  )

  const init = useCallback(() => {
    if (WS_URI) {
      const webSocket = new WebSocket(WS_URI)
      setWebSocket(webSocket)
    }
  }, [])

  const closeConnection = useCallback(() => {
    webSocket?.close()
  }, [webSocket])

  return {
    init,
    status,
    remoteDescription$,
    sendLocalDescription,
    closeConnection
  }
}

export default useWebSocket
