import { FC, memo, useState, useMemo } from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { motion } from 'framer-motion'

import Link from '../link'
import type { Animal } from 'lib/graphql'

const getAge = (timestamp: number) => ~~((Date.now() - timestamp) / 31557600000)

const severityColorMap = {
  low: 'yellow',
  medium: 'orange',
  high: 'red'
} as const

const eventColorMap = {
  lameness: 'indigo',
  heat: 'pink',
  activity: 'blue',
  calving: 'green',
  weight: 'orange',
  overlap: 'teal',
  'misc.': 'gray2'
} as const

interface Props extends Animal {}
const Card: FC<Props> = ({
  id,
  birth,
  lactation,
  gestating,
  notifications
}) => {
  const mostSevereNotifications = useMemo(() => {
    const mostRecentNotifications = [...notifications].sort(
      (a, b) => a.createdAt - b.createdAt
    )
    const notificationsSeverityOrder = { high: 1, medium: 2, low: 3 }

    return [...mostRecentNotifications].sort(
      (a, b) =>
        notificationsSeverityOrder[a.severity] -
        notificationsSeverityOrder[b.severity]
    )
  }, [notifications])

  const [info, setInfo] = useState<number | null>(
    mostSevereNotifications.length ? 0 : null
  )

  return (
    <Wrapper
      layout="position"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
    >
      <Id to={`${id}`}>#{id}</Id>

      {info === null ? (
        <Infos>
          <p>
            age : <strong>{getAge(birth)}</strong>
          </p>
          <p>
            lactation : <strong>{lactation}</strong>
          </p>
          <p>
            gestating : <strong>{gestating}</strong>
          </p>
        </Infos>
      ) : (
        <Infos className="notification">
          <div>
            <NotificationType
              beforeColor={eventColorMap[mostSevereNotifications[info].type]}
              afterColor={
                severityColorMap[mostSevereNotifications[info].severity]
              }
            >
              {mostSevereNotifications[info].type}
            </NotificationType>
            <p>
              <strong>
                {new Date(
                  mostSevereNotifications[info].createdAt
                ).toLocaleString(undefined, {
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                })}
              </strong>
            </p>
            <p>{mostSevereNotifications[info].description}</p>
          </div>
          <CowIcon
            className={`icon-cow-${mostSevereNotifications[info].type}`}
          />
        </Infos>
      )}

      <BadgesWrapper>
        {!!mostSevereNotifications.length &&
          mostSevereNotifications.map(({ type }, index) => (
            <Badge
              key={index}
              color={eventColorMap[type]}
              onClick={() => setInfo(index)}
            />
          ))}
        {!!mostSevereNotifications.length && (
          <Badge color={'gray1'} onClick={() => setInfo(null)} />
        )}
      </BadgesWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(motion.div)`
  position: relative;
  display: grid;
  grid-template-areas:
    'id badges'
    'infos infos'
    'infos infos'
    'infos infos';
  grid-gap: ${({ theme }) => theme.space.small};
  justify-content: space-between;
  padding: ${({ theme }) => theme.space.normal};
  background: ${({ theme }) => `${theme.color.gray5}`};
  border-radius: 20px;
  will-change: transform;

  &:before,
  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    transition: opacity 0.25s;
  }

  &:hover {
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
  }
`

// TODO
const Id = styled(Link)`
  grid-area: id;
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.size.large};
`

const Infos = styled.div`
  grid-area: infos;
  font-size: ${({ theme }) => theme.font.size.small};
  height: 64px;
  overflow: visible;

  &.notification {
    display: flex;
    justify-content: space-between;
  }
`

const CowIcon = styled.i`
  display: block;
  height: 56px;
  font-size: 56px;
`

const NotificationType = styled.p<{
  beforeColor: keyof DefaultTheme['color']
  afterColor: keyof DefaultTheme['color']
}>`
  font-weight: bold;
  text-transform: uppercase;

  &:before,
  &:after {
    display: inline-block;
  }

  &:before {
    content: '';
    background: ${({ beforeColor, theme }) => theme.color[beforeColor]};
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-right: ${({ theme }) => theme.space.small};
  }

  &:after {
    content: '\f102';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: top;
    font-size: ${({ theme }) => theme.font.size.small};
    color: ${({ afterColor, theme }) => theme.color[afterColor]};
    margin-left: ${({ theme }) => theme.space.small};
  }
`

const BadgesWrapper = styled.div`
  grid-area: badges;
  display: flex;
  align-items: center;
  justify-self: flex-end;

  & > :not(:last-child) {
    margin-right: ${({ theme }) => theme.space.small};
  }
`

const Badge = styled.div<{ color: keyof DefaultTheme['color'] }>`
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background: ${({ theme, color }) => theme.color[color]};
  cursor: pointer;
`

export default memo(Card)
