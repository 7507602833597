import { gql } from '@apollo/client'

export interface ArchivePeriods {
  archivePeriods: {
    durationMs: number
    timestampMs: number
  }[]
}

export interface ArchivePeriodsVariables {
  cameraId: string
}

export const ARCHIVE_PERIODS_QUERY = gql`
  query archivePeriods($cameraId: String!) {
    archivePeriods(cameraId: $cameraId) {
      durationMs
      timestampMs
    }
  }
`
