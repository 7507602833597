import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styled, { DefaultTheme } from 'styled-components'

import { ThreeHtml, useTheme, TextInput, Button, Pill, Spacer } from 'lib/ui'

import Color from './color'

interface FormState {
  name: string
  category: string
  color: keyof DefaultTheme['color']
}
interface Props {
  type: 'area' | 'line'
  name: string
  category: string
  color: keyof DefaultTheme['color']
  onComplete: (formState: FormState) => any
  onLeave: () => any
}
const Modal: FC<Props> = ({
  type,
  name: currentName,
  category: currentCategory,
  color: currentColor,
  onComplete,
  onLeave
}) => {
  const { handleSubmit, watch, setValue, register } = useForm<FormState>({
    defaultValues: {
      name: currentName,
      category: currentCategory,
      color: currentColor
    }
  })

  const onSubmit = handleSubmit(onComplete)

  const category = watch('category')

  useEffect(() => {
    setValue(
      'color',
      category === 'drinkingTank' || category === 'fence'
        ? 'blue'
        : category === 'stall' || category === 'counting'
        ? 'red'
        : category === 'feedingTable' || category === 'wall'
        ? 'green'
        : 'white'
    )
  }, [category, setValue])
  const { theme } = useTheme()

  return (
    <ThreeHtml
      fullscreen
      style={{
        padding: theme.space.normal,
        paddingTop: 72,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Form onSubmit={onSubmit}>
        <i className="fas fa-times" onClick={onLeave}></i>
        <Spacer />
        <h1>Customize {type}</h1>
        <Label htmlFor="name">Label</Label>
        <Spacer />
        <Row>
          <TextInput
            ref={register({ minLength: 2, maxLength: 20 })}
            name="name"
            id="name"
            placeholder={`${type} something...`}
            autoComplete="off"
          />
        </Row>

        <Label>Category</Label>
        <Spacer />
        <Row>
          {type === 'area' ? (
            <>
              <Pill
                id="drinkingTank"
                name="category"
                value="drinkingTank"
                ref={register({ required: true })}
              >
                Drinking Tank
              </Pill>
              <Spacer />
              <Pill
                id="stall"
                name="category"
                value="stall"
                ref={register({ required: true })}
              >
                Stall
              </Pill>
              <Spacer />
              <Pill
                id="feedingTable"
                name="category"
                value="feedingTable"
                ref={register({ required: true })}
              >
                Feeding Table
              </Pill>
              <Spacer />
              <Pill
                id="none"
                name="category"
                value="none"
                ref={register({ required: true })}
              >
                None
              </Pill>
            </>
          ) : type === 'line' ? (
            <>
              <Pill
                id="fence"
                name="category"
                value="fence"
                ref={register({ required: true })}
              >
                Fence
              </Pill>
              <Spacer />
              <Pill
                id="counting"
                name="category"
                value="counting"
                ref={register({ required: true })}
              >
                Counting
              </Pill>
              <Spacer />
              <Pill
                id="wall"
                name="category"
                value="wall"
                ref={register({ required: true })}
              >
                Wall
              </Pill>
              <Spacer />
              <Pill
                id="none"
                name="category"
                value="none"
                ref={register({ required: true })}
              >
                None
              </Pill>
            </>
          ) : (
            <></>
          )}
        </Row>

        <Label>Color</Label>
        <Spacer />
        <Row>
          <Color
            disabled={
              category === 'none' ||
              (category !== 'drinkingTank' && category !== 'fence')
            }
            ref={register({ required: true })}
            color={'blue'}
          />
          <Spacer />
          <Color
            disabled={
              category === 'none' ||
              (category !== 'stall' && category !== 'counting')
            }
            ref={register({ required: true })}
            color={'red'}
          />
          <Spacer />
          <Color
            disabled={
              category === 'none' ||
              (category !== 'feedingTable' && category !== 'wall')
            }
            ref={register({ required: true })}
            color={'green'}
          />
          <Spacer />
          <Color
            disabled={category !== 'none'}
            ref={register({ required: true })}
            value={'white'}
            color={'gray3'}
          />
          <Spacer />
          <Color
            disabled={category !== 'none'}
            ref={register({ required: true })}
            color={'indigo'}
          />
          <Spacer />
          <Color
            disabled={category !== 'none'}
            ref={register({ required: true })}
            color={'orange'}
          />
          <Spacer />
          <Color
            disabled={category !== 'none'}
            ref={register({ required: true })}
            color={'pink'}
          />
          <Spacer />

          <Color
            disabled={category !== 'none'}
            ref={register({ required: true })}
            color={'purple'}
          />
          <Spacer />
          <Color
            disabled={category !== 'none'}
            ref={register({ required: true })}
            color={'teal'}
          />
          <Spacer />
          <Color
            disabled={category !== 'none'}
            ref={register({ required: true })}
            color={'yellow'}
          />
        </Row>
        <Spacer />
        <Button>
          <i className="fas fa-save" />
          <span>Save</span>
        </Button>
      </Form>
    </ThreeHtml>
  )
}

const Form = styled.form`
  z-index: 2;
  position: absolute;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.gray6};
  border-radius: ${({ theme }) => theme.borderRadius};
  max-width: ${({ theme }) => `calc(100% - (${theme.space.normal} * 2))`};
  padding: ${({ theme }) => theme.space.normal};
  overflow: hidden;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  pointer-events: all;

  & .fa-times {
    cursor: pointer;
  }
`

const Row = styled.div<{ isEnd?: boolean }>`
  align-self: ${({ isEnd }) => (isEnd ? 'flex-end' : 'flex-start')};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  & > input[type='text'] {
    width: 100%;
  }
`

const Label = styled.label`
  margin-top: ${({ theme }) => theme.space.normal};
  font-weight: bold;
`

export default Modal
