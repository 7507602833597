import styled from 'styled-components'

const Input = styled.input.attrs({
  name: 'searchTerm',
  type: 'text',
  autoComplete: 'off'
})`
  width: ${({ theme }) =>
    `calc(100% - 40px - ${theme.space.small} - ${theme.space.normal})`};
  outline: none;
  border: none;
  font-size: ${({ theme }) => theme.font.size.normal};
  color: inherit;
  background: transparent;
`

export default Input
