import { ApolloProvider } from '@apollo/client'

import Logo from './logo'
import Search from './search'
import Links from './links'
import Pages from './pages'

import { HistoryProvider } from 'lib/history'
import { apolloClient } from 'lib/graphql'
import { StreamerProvider, PlaybackProvider } from 'lib/streamer'
import { ThemeProvider, GlobalStyle } from 'lib/ui'

const App = () => {
  return (
    <>
      <ApolloProvider client={apolloClient}>
        <StreamerProvider>
          <PlaybackProvider>
            <ThemeProvider>
              <HistoryProvider>
                <Links />
                <Logo />
                <Search />
                <Pages />
                <GlobalStyle />
              </HistoryProvider>
            </ThemeProvider>
          </PlaybackProvider>
        </StreamerProvider>
      </ApolloProvider>
    </>
  )
}

export default App
