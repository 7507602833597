import { Vector2, Vector3 } from 'three'

const PLANE_Y = -10_000

function planeWorldConverter(
  to: '2d',
  points: [x: number, y: number, z: number]
): Vector2
function planeWorldConverter(
  to: '3d',
  points: [x: number, y: number, z: number]
): Vector3
function planeWorldConverter(
  to: '2d flat',
  points: [x: number, y: number, z: number]
): [x: number, y: number]
function planeWorldConverter(
  to: '3d flat',
  points: [x: number, y: number, z: number]
): [x: number, y: number, z: number]
function planeWorldConverter(
  to: string,
  points: [x: number, y: number, z: number]
) {
  const [x, y, z] = points
  const ratio = PLANE_Y / y
  const planeX = ratio * x
  const planeZ = ratio * z

  switch (to) {
    case '2d':
      return new Vector2(planeX, -planeZ)
    case '3d':
      return new Vector3(planeX, -PLANE_Y, planeZ)
    case '2d flat':
      return [planeX, -planeZ] as const
    case '3d flat':
      return [planeX, -PLANE_Y, planeZ] as const
    default:
      return points
  }
}

export default planeWorldConverter
