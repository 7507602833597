import type { Light } from 'styled-components'

const light: Light = {
  white: '#f2f2f7',
  black: '#1c1c1e',

  gray: '#1c1c1e',
  gray1: '#8e8e93',
  gray2: '#aeaeb2',
  gray3: '#c7c7cc',
  gray4: '#d1d1d6',
  gray5: '#e5e5ea',
  gray6: '#f2f2f7',

  blue: '#007aff',
  green: '#34c759',
  indigo: '#5856d6',
  orange: '#ff9500',
  pink: '#ff2d55',
  purple: '#af52de',
  red: '#ff3b30',
  teal: '#5ac8fa',
  yellow: '#ffcc00'
}

export default light
