import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        color: inherit;
    }

    :root {
        font-size: ${({ theme }) => theme.font.size.root};
    }

    html,
    body {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        font-size: ${({ theme }) => theme.font.size.normal};
        font-family: 'Poppins', sans-serif;
        color: ${({ theme }) => theme.color.gray};
        background: linear-gradient(to right, #005c97, #363795);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

    }

    #app {
        width: 100%;
        height: 100%;
    }

    a {
        text-decoration: none;
    }

    input, button, canvas {
        outline: none;
    }
`

export default GlobalStyle
