import { gql } from '@apollo/client'

export interface PhysicalEntities {
  cows: {
    ids: string[]
    timestamp: number
  }[]
  positions: {
    cowId: string
    coordsAtTimestamp: {
      timestamp: number
      coords: [number, number, number]
    }[]
  }[]
}

export const PHYSICAL_ENTITIES_QUERY = gql`
  query physicalEntities($cameraId: String!, $start: Float!, $end: Float!) {
    physicalEntities(cameraId: $cameraId, start: $start, end: $end) {
      cows {
        ids
        timestamp
      }
      positions {
        cowId
        coordsAtTimestamp {
          coords
          timestamp
        }
      }
    }
  }
`
