import { gql } from '@apollo/client'

export interface Animal {
  id: string
  birth: number
  lactation: number
  gestating: number
  notifications: {
    createdAt: number
    type:
      | 'lameness'
      | 'heat'
      | 'activity'
      | 'calving'
      | 'weight'
      | 'overlap'
      | 'misc.'
    description: string
    severity: 'low' | 'medium' | 'high'
  }[]
}

export const ANIMAL_QUERY = gql`
  query animal($id: String!) {
    animal(id: $id) {
      id
      birth
      lactation
      gestating
      notifications {
        createdAt
        type
        description
        severity
      }
    }
  }
`

export interface AnimalQueryVariables {
  id: string
}

export const ANIMALS_QUERY = gql`
  query animals($hasNotificationType: [String!]!, $severities: [String!]!) {
    animals(
      hasNotificationType: $hasNotificationType
      severities: $severities
    ) {
      id
      birth
      lactation
      gestating
      notifications {
        createdAt
        type
        description
        severity
      }
    }
  }
`

export interface AnimalsQueryVariables {
  hasNotificationType: string[]
  severities: string[]
}
