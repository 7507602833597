import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { usePlayback } from 'lib/streamer'

import Input from './input'
import Icon from './icon'

interface FormData {
  searchTerm: string
}

interface Props {
  expandedMenu: boolean
  onMenuToggle: () => any
}
const Bar: FC<Props> = ({ expandedMenu, onMenuToggle }) => {
  const { register, setValue } = useForm<FormData>()
  const { playingCamera } = usePlayback()

  useEffect(() => {
    if (!expandedMenu) setValue('searchTerm', '')
  }, [expandedMenu, setValue])

  return (
    <Wrapper onClick={() => !expandedMenu && onMenuToggle()}>
      <Icon
        expandedMenu={expandedMenu}
        onClick={() => expandedMenu && onMenuToggle()}
      />
      <Input
        ref={register}
        placeholder={playingCamera?.name || 'Camera, Cow ID...'}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: ${({ theme }) => theme.space.small};
  }
`

export default Bar
