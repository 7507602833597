import { Suspense, useLayoutEffect } from 'react'
import { useOutlet } from 'react-router'

import { useStreamer, usePlayback } from 'lib/streamer'
import SceneProvider from './scene-provider'
import Camera from './camera'
import Controls from './controls'
import Dome from './dome'
import Layers from './layers'
import Timeline from './timeline'
import Loader from './loader'

const Scene = () => {
  const outlet = useOutlet()
  const { status } = useStreamer()
  const { play, playback } = usePlayback()

  useLayoutEffect(() => {
    if (status === 'ready') {
      play()
    }
  }, [play, status])

  return (
    <>
      <SceneProvider>
        <Camera />
        <Controls />
        <Suspense fallback={null}>
          <Dome />
        </Suspense>
        <Suspense fallback={null}>{outlet}</Suspense>
      </SceneProvider>
      <Layers />
      <Timeline />

      {(playback === 'pending' || status === 'loading') && <Loader />}
    </>
  )
}

export default Scene
