import styled, { DefaultTheme } from 'styled-components'

interface Props {
  size?: keyof DefaultTheme['space']
}

const Spacer = styled.div<Props>`
  height: ${({ theme, size = 'normal' }) => theme.space[size]};
  width: ${({ theme, size = 'normal' }) => theme.space[size]};
`

export default Spacer
