import type { FC } from 'react'
import { getApolloContext } from '@apollo/client'
import { Canvas } from 'react-three-fiber'
import { useContextBridge } from '@react-three/drei'

import { HistoryContext } from 'lib/history'
import { StreamerContext, PlaybackContext } from 'lib/streamer'
import { ThemeContext } from 'lib/ui'

const SceneProvider: FC = ({ children }) => {
  const ApolloContext = getApolloContext()

  const ContextBridge = useContextBridge(
    StreamerContext,
    PlaybackContext,
    ApolloContext,
    HistoryContext,
    ThemeContext
  )

  return (
    <Canvas
      gl={{
        antialias: true,
        preserveDrawingBuffer: true
      }}
      raycaster={{ params: { Points: { threshold: 220 } } }}
      id="scene">
      <ContextBridge>{children}</ContextBridge>
    </Canvas>
  )
}

export default SceneProvider
