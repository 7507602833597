import { useState, useEffect } from 'react'
import { merge } from 'rxjs'
import { fromEvent, FromEventTarget } from 'rxjs/internal/observable/fromEvent'
import { mapTo, startWith } from 'rxjs/operators'

const useStatus = <E = Event>(target?: FromEventTarget<E>) => {
  const [status, setStatus] = useState<
    'openned' | 'failed' | 'closed' | 'loading'
  >('closed')

  useEffect(() => {
    if (target) {
      const subscriber = merge(
        ...([
          { event: 'open', state: 'openned' },
          { event: 'error', state: 'failed' },
          { event: 'close', state: 'closed' }
        ] as const).map(({ event, state }) =>
          fromEvent<E>(target, event).pipe(
            mapTo(state),
            startWith('loading' as const)
          )
        )
      ).subscribe(setStatus)

      return () => subscriber.unsubscribe()
    }
  }, [target])

  return status
}

export default useStatus
