import { forwardRef } from 'react'
import styled, { DefaultTheme } from 'styled-components'

interface Props {
  color?: keyof DefaultTheme['color']
  value?: keyof DefaultTheme['color']
  children?: string
  disabled?: boolean
}
const Color = forwardRef<HTMLInputElement, Props>(
  ({ color = 'white', value, disabled = false, children }, ref) => {
    return (
      <Wrapper>
        <Input
          color={color}
          ref={ref}
          name="color"
          id={color}
          value={value || color}
          disabled={disabled}
        />
        <label htmlFor={color}>{children}</label>
      </Wrapper>
    )
  }
)

const Wrapper = styled.div`
  display: flex;
`

const Input = styled.input.attrs({ type: 'radio' })<{
  color: Props['color']
}>`
  display: none;

  + label {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.gray5};
    transition-properties: background, color;
    transition-duration: 0.125s;
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  &:checked + label {
    opacity: 1;
  }

  &:disabled,
  &[disabled] + label {
    overflow: hidden;

    &:hover {
      opacity: 0.5;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      cursor: not-allowed;
      background: ${({ theme }) => `repeating-linear-gradient(
        -55deg,
        ${theme.color.gray6} 1px, 
        ${theme.color.gray3} 2px, 
        ${theme.color.gray3} 11px,
        ${theme.color.gray6} 12px,
        ${theme.color.gray6} 20px 
      )`};
      opacity: 0.5;
      background-size: 300% 100%;
      background-repeat: repeat-x;
      animation: background 5s infinite linear;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
    }
  }
`

export default Color
