import { useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'

import { useTheme } from 'lib/ui'
import { Card } from 'lib/ui/animal'
import { Animal, ANIMALS_QUERY, AnimalsQueryVariables } from 'lib/graphql'

const eventColorMap = {
  lameness: 'indigo',
  heat: 'pink',
  activity: 'blue',
  calving: 'green',
  weight: 'orange',
  overlap: 'teal',
  'misc.': 'gray2'
} as const

const Root = () => {
  const [notificationTypes, setNotificationTypes] = useState<string[]>([])
  const [notificationSeverities, setNotificationSeverities] = useState<
    string[]
  >([])

  const { data } = useQuery<{ animals: Animal[] }, AnimalsQueryVariables>(
    ANIMALS_QUERY,
    {
      variables: {
        hasNotificationType: notificationTypes,
        severities: notificationSeverities
      }
    }
  )

  const {
    theme: { color }
  } = useTheme()

  return (
    <>
      <FiltersList>
        <div>
          <p>Events</p>
          <ul>
            {new Array(
              ...([
                'lameness',
                'heat',
                'activity',
                'calving',
                'weight',
                'overlap',
                'misc.'
              ] as const)
            ).map((type) => (
              <Filter
                key={type}
                color={color[eventColorMap[type]]}
                active={notificationTypes.includes(type)}
                onClick={() => {
                  setNotificationTypes((types) =>
                    types.includes(type)
                      ? types.filter((f) => f !== type)
                      : [...types, type]
                  )
                }}
              >
                {type}
              </Filter>
            ))}
          </ul>
        </div>
        <Separator />
        <div>
          <p>Priority</p>

          <ul>
            <Filter
              color={color.red}
              active={notificationSeverities.includes('high')}
              onClick={() => {
                setNotificationSeverities((notificationSeverities) =>
                  notificationSeverities.includes('high')
                    ? notificationSeverities.filter((f) => f !== 'high')
                    : [...notificationSeverities, 'high']
                )
              }}
            >
              High
            </Filter>
            <Filter
              color={color.orange}
              active={notificationSeverities.includes('medium')}
              onClick={() => {
                setNotificationSeverities((notificationSeverities) =>
                  notificationSeverities.includes('medium')
                    ? notificationSeverities.filter((f) => f !== 'medium')
                    : [...notificationSeverities, 'medium']
                )
              }}
            >
              Medium
            </Filter>
            <Filter
              color={color.yellow}
              active={notificationSeverities.includes('low')}
              onClick={() => {
                setNotificationSeverities((notificationSeverities) =>
                  notificationSeverities.includes('low')
                    ? notificationSeverities.filter((f) => f !== 'low')
                    : [...notificationSeverities, 'low']
                )
              }}
            >
              Low
            </Filter>
          </ul>
        </div>
      </FiltersList>

      <FiltersList>
        <div>
          <p>Batches</p>
          <ul>
            <Filter color="#424242" active={false}>
              Batch 1
            </Filter>
            <Filter color="#424242" active={false}>
              Batch 2
            </Filter>
          </ul>
        </div>
      </FiltersList>

      <Grid>
        <AnimatePresence initial={false}>
          {data?.animals.map((props) => (
            <Card key={props.id} {...props} />
          ))}
        </AnimatePresence>
      </Grid>
    </>
  )
}

const FiltersList = styled.div`
  display: flex;
  flex-wrap: wrap;

  div {
    margin-bottom: ${({ theme }) => theme.space.small};

    ul {
      list-style: none;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      & > :not(:last-child) {
        margin-right: ${({ theme }) => theme.space.normal};
      }
    }
  }
`

const Separator = styled.div`
  height: 40px;
  width: 1px;
  background: ${({ theme }) => theme.color.gray};
  margin: 0 ${({ theme }) => theme.space.normal};
  align-self: flex-end;
`

const Filter = styled.li<{ color: string; active: boolean }>`
  margin-top: ${({ theme }) => theme.space.small};
  padding: ${({ theme }) => `${theme.space.small} ${theme.space.normal}`};
  border-radius: 20px;
  color: ${({ theme }) => theme.color.white};
  background: ${({ color }) => color};
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  transition-properties: background, color;
  transition-duration: 0.125s;
  cursor: pointer;
  text-transform: capitalize;
  user-select: none;

  &:hover {
    background: ${({ color }) => color};
  }
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: ${({ theme }) => theme.space.normal};
  margin-top: ${({ theme }) => theme.space.large};
`

export default Root
