import { gql } from '@apollo/client'

export interface Camera {
  id: string
  name: string
  isActive: boolean
}

export const CAMERAS_LIST = gql`
  query cameras {
    cameras {
      id
      name
      isActive
    }
  }
`
