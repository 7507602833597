import { FC, createContext, useEffect, useRef, useMemo } from 'react'
import { Observable } from 'rxjs'

import useWebRTC from './use-web-rtc'
import useWebSocket from './use-web-socket'

const useContextValue = () => {
  const {
    init: connectWebRTC,
    iceCandidate$,
    track$,
    setRemoteDescription,
    sendCommand,
    dataChannel$,
    peerStatus,
    dataChannelStatus
  } = useWebRTC()
  const {
    init: connectWebSocket,
    remoteDescription$,
    sendLocalDescription,
    status: webSocketStatus
  } = useWebSocket()

  // initialization
  useEffect(() => {
    connectWebSocket()
  }, [connectWebSocket])

  useEffect(() => {
    if (webSocketStatus === 'openned') {
      connectWebRTC()
    }
  }, [webSocketStatus, connectWebRTC])

  // Remote description listener
  useEffect(() => {
    const subscriber = remoteDescription$?.subscribe(setRemoteDescription)
    return () => subscriber?.unsubscribe()
  }, [remoteDescription$, setRemoteDescription])

  // Local description listener
  useEffect(() => {
    if (sendLocalDescription) {
      const subscriber = iceCandidate$?.subscribe(sendLocalDescription)
      return () => subscriber?.unsubscribe()
    }
  }, [iceCandidate$, sendLocalDescription])

  // Stream
  const stream = useRef(new MediaStream())
  useEffect(() => {
    const subscriber = track$?.subscribe((e) => {
      stream.current.addTrack(e.track)
    })
    return () => subscriber?.unsubscribe()
  }, [track$])

  // Status
  const status = useMemo(() => {
    if (
      peerStatus === 'loading' ||
      dataChannelStatus === 'loading' ||
      webSocketStatus === 'loading'
    ) {
      return 'loading'
    }
    if (
      peerStatus === 'failed' ||
      dataChannelStatus === 'failed' ||
      webSocketStatus === 'failed'
    ) {
      return 'error'
    }
    if (
      peerStatus === 'openned' &&
      dataChannelStatus === 'openned' &&
      webSocketStatus === 'openned'
    ) {
      return 'ready'
    }
    return 'error'
  }, [dataChannelStatus, peerStatus, webSocketStatus])

  return {
    status,
    stream: stream.current,
    sendCommand,
    dataChannel$
  }
}

const StreamerContext = createContext<ReturnType<typeof useContextValue>>({
  status: 'loading',
  stream: new MediaStream(),
  sendCommand: () => {},
  dataChannel$: new Observable()
})

const StreamerProvider: FC = ({ children }) => {
  const value = useContextValue()

  return (
    <StreamerContext.Provider value={value}>
      {children}
    </StreamerContext.Provider>
  )
}

export { StreamerProvider }
export default StreamerContext
