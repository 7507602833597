import {
  useState,
  useRef,
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect
} from 'react'
import { useQuery, QueryResult } from '@apollo/client'
import { fromEvent, interval, Subscription } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import type { FromEventTarget } from 'rxjs/internal/observable/fromEvent'

import { useReactive } from 'lib/utils'
import { CAMERAS_LIST, Camera } from 'lib/graphql'

import StreamerContext from './streamer-context'

export type CommandType = 'play' | 'pause' | 'stop'
type CommandOptions = {
  cameraId: string
  timestampInMilliseconds: number
  width: number
  height: number
  isHighQuality: boolean
  maxBitrateKbps: number
}

const [DEFAULT_BITRATE, MIN_BITRATE, MAX_BITRATE] = [4050, 300, 24300]

const getMaxBitrate = (kbps: number) =>
  Math.min(
    Math.max(
      Math.ceil(kbps / MIN_BITRATE) * MIN_BITRATE - MIN_BITRATE,
      MIN_BITRATE
    ),
    MAX_BITRATE
  ) / 2

const connection =
  navigator.connection || navigator.mozConnection || navigator.webkitConnection

const videoSize = Math.min(
  1000,
  Math.min(window.innerHeight, window.innerWidth) / 2
)

const useContextValue = () => {
  const [playback, setPlayback] = useState<
    'playing' | 'paused' | 'stopped' | 'pending'
  >('stopped')
  const cameraQuery = useQuery<{ cameras: Camera[] }>(CAMERAS_LIST)
  const [playingCamera, setPlayingCamera] = useState<{
    id: string
    name: string
  } | null>(null)
  const commandOptions = useRef<CommandOptions>({
    cameraId:
      localStorage.getItem('cameraID') ||
      'fc5210ca-383a-efde-7161-3a431e4c3a20',
    timestampInMilliseconds: 0,
    width: videoSize,
    height: videoSize,
    isHighQuality: true,
    maxBitrateKbps: 2100
  })
  const { sendCommand, dataChannel$ } = useContext(StreamerContext)
  const [maxBitrate, setMaxBitrate] = useReactive(DEFAULT_BITRATE)

  useEffect(() => {
    let subscriber: Subscription | null = null
    if (connection?.downlink) {
      subscriber = fromEvent(connection as FromEventTarget<Event>, 'change')
        .pipe(
          startWith(null),
          map(() => {
            if (connection?.downlink) {
              const kbps = connection.downlink * 1_000
              const maxBitrate = getMaxBitrate(kbps)
              return maxBitrate
            } else {
              return DEFAULT_BITRATE
            }
          })
        )
        .subscribe(setMaxBitrate)
    } else {
      subscriber = interval(8_000).subscribe(async () => {
        try {
          let startTime = +new Date()
          await fetch('https://eu.httpbin.org/bytes/500000')
          const endTime = +new Date()
          const duration = (endTime - startTime) / 1000
          const bitsLoaded = 500000 * 8
          const bps = (bitsLoaded / duration).toFixed(2)
          const kbps = +(+bps / 1000).toFixed(2)
          const maxBitrate = getMaxBitrate(kbps)
          setMaxBitrate(maxBitrate)
        } catch {}
      })
    }

    return () => subscriber?.unsubscribe()
  }, [setMaxBitrate])

  useEffect(() => {
    const subscriber = dataChannel$?.subscribe(({ data }) => {
      if (data.eventType === 'paused') {
        commandOptions.current = {
          ...commandOptions.current,
          timestampInMilliseconds: data.timestampInMilliseconds
        }
        setPlayback('paused')
      }
      if (data.eventType === 'ended') {
        setPlayback('stopped')
      }
      if (data.eventType === 'playing') {
        const playingCamera = cameraQuery.data?.cameras.find(
          ({ id }) => id === data.cameraId
        )
        setPlayingCamera(
          playingCamera
            ? { id: playingCamera.id, name: playingCamera.name }
            : null
        )
        setPlayback('playing')
      }
    })
    return () => subscriber?.unsubscribe()
  }, [cameraQuery.data?.cameras, dataChannel$])

  const play = useCallback(
    (options: Partial<CommandOptions> = {}) => {
      setPlayback('pending')
      commandOptions.current = {
        ...commandOptions.current,
        ...options
      }
      console.log(commandOptions.current)

      sendCommand({
        commandType: 'play',
        commandOptions: commandOptions.current
      })
    },
    [sendCommand]
  )

  const pause = useCallback(() => {
    setPlayback('pending')
    sendCommand({
      commandType: 'pause',
      commandOptions: {}
    })
  }, [sendCommand])

  useEffect(() => {
    const subscriber = maxBitrate.subscribe((maxBitrate) => {
      if (maxBitrate !== commandOptions.current.maxBitrateKbps) {
        if (playback === 'playing') {
          play({ maxBitrateKbps: maxBitrate })
        } else {
          commandOptions.current.maxBitrateKbps = maxBitrate
        }
      }
    })
    return () => subscriber.unsubscribe()
  }, [maxBitrate, play, playback])

  return { playback, playingCamera, cameraQuery, play, pause }
}

const PlaybackContext = createContext<ReturnType<typeof useContextValue>>({
  playback: 'stopped',
  playingCamera: null,
  cameraQuery: {} as QueryResult,
  play: () => {},
  pause: () => {}
})

const PlaybackProvider: FC = ({ children }) => {
  const value = useContextValue()

  return (
    <PlaybackContext.Provider value={value}>
      {children}
    </PlaybackContext.Provider>
  )
}

export { PlaybackProvider }
export default PlaybackContext
