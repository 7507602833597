import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { Pill, Spacer, TextInput } from 'lib/ui'
import 'react-datepicker/dist/react-datepicker.css'

const New = () => {
  const { register } = useForm()

  return (
    <>
      <SectionTitle>Title</SectionTitle>
      <TextInput placeholder="Title" />

      <Spacer />

      <SectionTitle>Who ?</SectionTitle>
      <SearchInputWrapper>
        <TextInput placeholder="Animal, Batch..." />
      </SearchInputWrapper>

      <Spacer />
      <SectionTitle>When ?</SectionTitle>
      <TimeFiltersWrapper>
        <Pill
          id="day"
          name="time-filter"
          value="day"
          ref={register({ required: true })}
        >
          <i className="fas fa-clock"></i>
          <span>day</span>
        </Pill>
        <Pill
          id="date"
          name="time-filter"
          value="date"
          ref={register({ required: true })}
        >
          <i className="fas fa-calendar-alt"></i>
          <span>date</span>
        </Pill>
      </TimeFiltersWrapper>
      <Spacer />
      <SectionTitle>Accumulator ?</SectionTitle>
      <Spacer />
      <SectionTitle>Wait ?</SectionTitle>
      <Spacer />
      <SectionTitle>Threshold ?</SectionTitle>
      <input type="number" defaultValue={70} min={1} max={100} />
      <Spacer />
      <SectionTitle>Action</SectionTitle>
    </>
  )
}

const SectionTitle = styled.p`
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.space.normal};
`

const SearchInputWrapper = styled.div`
  position: relative;

  &:before {
    position: absolute;
    content: '\f002';
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Font Awesome 5 Pro';
    font-weight: bold;
    border-radius: 20px;
    background: ${({ theme }) => theme.color.gray5};
    pointer-events: none;
  }
  & > ${TextInput} {
    padding-left: 48px;
  }
`
const TimeFiltersWrapper = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: ${({ theme }) => theme.space.small};
  }
`

export default New
