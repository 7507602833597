import {
  FC,
  Dispatch,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useRef,
  useMemo
} from 'react'
import { fromEvent } from 'rxjs'
import { switchMap, map, takeUntil, endWith } from 'rxjs/operators'
import { Float32BufferAttribute, Points, Texture, Vector3 } from 'three'
import { PointerEvent, useThree } from 'react-three-fiber'

interface Props {
  points: [x: number, y: number, z: number][]
  setPoints: Dispatch<SetStateAction<Props['points']>>
  texture: Texture
  preventClosing?: boolean
  onChanged?: (points: Props['points']) => any
}
const ResizablePoints: FC<Props> = ({
  points,
  setPoints,
  texture,
  preventClosing = false
}) => {
  const pointsRef = useRef<Points>()
  const intermediatePointsRef = useRef<Points>()

  const intermediatePoints = useMemo(() => {
    let intermediatePoints: [x: number, y: number, z: number][] = []
    for (
      let i = 0;
      i < (preventClosing ? points.length - 1 : points.length);
      i++
    ) {
      const current = new Vector3(...points[i])
      const next = new Vector3(...(points[i + 1] || points[0]))
      const intermediatePoint = new Vector3()
        .addVectors(current, next)
        .divideScalar(2)
      intermediatePoints.push(intermediatePoint.toArray())
    }
    return intermediatePoints
  }, [points, preventClosing])

  const { scene } = useThree()

  const resizeGeometries = useCallback(
    (e: PointerEvent, grabbedPoint: number | null) => {
      if (typeof grabbedPoint === 'number') {
        setPoints((points) =>
          points.map((point, index) =>
            index === grabbedPoint ? [...e.point.normalize().toArray()] : point
          )
        )
      }
    },
    [setPoints]
  )

  useLayoutEffect(() => {
    const dome = scene.getObjectByName('dome')
    if (dome && pointsRef.current) {
      const pointerDown$ = fromEvent<PointerEvent>(
        pointsRef.current,
        'pointerdown'
      )
      const pointerMove$ = fromEvent<PointerEvent>(dome, 'pointermove')
      const pointerUp$ = fromEvent<PointerEvent>(dome, 'pointerup')
      const drag$ = pointerDown$.pipe(
        switchMap((pointerDownEvent) =>
          pointerMove$
            .pipe(
              map((pointerMoveEvent) => ({
                type: 'resizing' as const,
                data: [
                  pointerMoveEvent,
                  pointerDownEvent?.index ?? null
                ] as const
              })),
              takeUntil(pointerUp$)
            )
            .pipe(
              endWith({
                type: 'end' as const,
                data: null
              })
            )
        )
      )
      const subscriber = drag$.subscribe(({ type, data }) => {
        if (type === 'resizing' && data) {
          scene.dispatchEvent({ type: 'controls', enabled: false })
          resizeGeometries(...data)
        }
        if (type === 'end') {
          scene.dispatchEvent({ type: 'controls', enabled: true })
        }
      })
      return () => subscriber.unsubscribe()
    }
  }, [resizeGeometries, scene])

  useLayoutEffect(() => {
    intermediatePointsRef.current?.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(
        intermediatePoints.flatMap((point) =>
          point.map((coord) => coord * 10_000)
        ),
        3
      )
    )
    pointsRef.current?.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(
        points.flatMap((point) => point.map((coord) => coord * 10_000)),
        3
      )
    )

    if (
      pointsRef.current?.geometry &&
      intermediatePointsRef.current?.geometry
    ) {
      pointsRef.current.geometry.attributes.position.needsUpdate = true
      pointsRef.current.geometry.computeBoundingSphere()
      pointsRef.current.geometry.boundingSphere!.radius += 0.1
      pointsRef.current.updateMatrix()
      intermediatePointsRef.current.geometry.attributes.position.needsUpdate = true
      intermediatePointsRef.current.geometry.computeBoundingSphere()
      intermediatePointsRef.current.geometry.boundingSphere!.radius += 0.1
      intermediatePointsRef.current.updateMatrix()
    }
  }, [points, intermediatePoints])

  const onIntermediatePointClick = useCallback(
    (e: PointerEvent) => {
      setPoints((points) =>
        points.reduce(
          (acc, cur, index) =>
            e.index === index
              ? [...acc, cur, e.point.normalize().toArray()]
              : [...acc, cur],
          [] as [x: number, y: number, z: number][]
        )
      )
      const pointerDownEvent = e
      if (pointerDownEvent.index) {
        pointerDownEvent.index += 1
        pointsRef.current?.dispatchEvent(pointerDownEvent)
      }
    },
    [setPoints]
  )

  return (
    <group>
      <points
        ref={pointsRef}
        renderOrder={1}
        matrixAutoUpdate
        onPointerDown={(e) => {
          pointsRef.current?.dispatchEvent(e)
        }}>
        <pointsMaterial
          depthTest={false}
          attach="material"
          sizeAttenuation={false}
          map={texture}
          transparent
          size={20}
        />
      </points>

      <points
        ref={intermediatePointsRef}
        renderOrder={1}
        matrixAutoUpdate
        onPointerDown={onIntermediatePointClick}>
        <pointsMaterial
          depthTest={false}
          attach="material"
          sizeAttenuation={false}
          map={texture}
          transparent
          size={10}
        />
      </points>
    </group>
  )
}

export default ResizablePoints
