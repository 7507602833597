import styled from 'styled-components'

import Link from './link'

const ButtonLink = styled(Link)`
  height: 40px;
  padding: ${({ theme }) => `0 ${theme.space.normal}`};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => `${theme.color.gray5}`};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  font-weight: bold;

  & > :not(:last-child) {
    margin-right: ${({ theme }) => theme.space.small};
  }
`

export default ButtonLink
