import { useState, useRef, useLayoutEffect, useMemo } from 'react'
import { fromEvent, race } from 'rxjs'
import { Points, Group, Vector3, LinearFilter, Texture } from 'three'
import { Line2 } from 'three/examples/jsm/lines/Line2'
import { useThree, useFrame } from 'react-three-fiber'
import { Line } from '@react-three/drei'
import { Tween } from '@tweenjs/tween.js'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'

import { useTracking } from 'lib/tracking'
import { ThreeHtml, useTheme } from 'lib/ui'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry'

const Tracking = () => {
  const ref = useRef<Points>()
  const menuRef = useRef<Group>()
  const lineRef = useRef<Line2>(null)

  const animals = useRef<{ [animal: string]: Vector3 }>({})
  const [[hovered, focused], setInteractions] = useState<
    [hovered: string | null, focused: string | null]
  >([null, null])

  const { points$ } = useTracking()
  const tween = useRef<Tween<{ [animal: string]: Vector3 }> | null>(null)
  const focusTween = useRef<Tween<{
    menuPosition: Vector3
    cameraPosition: Vector3
    zoom: number
  }> | null>(null)
  const { scene, camera, gl } = useThree()

  /* Points subscriber */
  useLayoutEffect(() => {
    const subscriber = points$?.subscribe(
      ({ currentPoints, nextPoints, averageDeltaTime }) => {
        tween.current = new Tween(currentPoints)
          .to(nextPoints, averageDeltaTime)
          .onUpdate((points) => {
            animals.current = points
            ref.current?.geometry
              .setFromPoints(Object.values(points))
              .computeBoundingSphere()
          })
          .start()
      }
    )

    return () => subscriber?.unsubscribe()
  }, [points$, scene, focused, camera])

  /* focus */
  useLayoutEffect(() => {
    if (focused) {
      const position = animals.current?.[focused] as Vector3 | undefined

      if (position) {
        const cameraOriginPosition = camera.position.clone()
        const cameraTargetPosition = camera.position
          .clone()
          .copy(position)
          .normalize()
          .negate()

        const menuOriginPosition = menuRef.current?.position.clone() || position
        const menuTargetPosition = position

        const originZoom = camera.zoom
        const targetZoom = Math.max(1, Math.min(3, -1 / (position.y / 10_000)))

        focusTween.current = new Tween({
          cameraPosition: cameraOriginPosition,
          menuPosition: menuOriginPosition,
          zoom: originZoom
        })
          .to(
            {
              cameraPosition: cameraTargetPosition,
              menuPosition: menuTargetPosition,
              zoom: targetZoom
            },
            200
          )
          .onUpdate(({ cameraPosition, menuPosition, zoom }) => {
            camera.position.copy(cameraPosition)
            camera.zoom = zoom
            camera.updateProjectionMatrix()
            camera.dispatchEvent({ type: 'zoom' })
            menuRef.current?.position.copy(menuPosition)
          })
          .start()
      } else {
        setInteractions(([hovered]) => [hovered, null])
      }
    } else {
      focusTween.current = null
    }
  }, [camera, focused])

  /* quit focus */
  useLayoutEffect(() => {
    if (focused) {
      const mouseDown$ = fromEvent(document, 'pointerdown')
      const wheel$ = fromEvent(document, 'wheel')
      const subscriber = race(mouseDown$, wheel$).subscribe(() =>
        setInteractions(([hovered]) => [hovered, null])
      )

      return () => subscriber.unsubscribe()
    }
  }, [focused])

  /* hover */
  useLayoutEffect(() => {
    if (hovered) {
      const position = animals.current?.[hovered]
      menuRef.current?.position.copy(position)
    }
  }, [hovered])

  useFrame(() => {
    tween.current?.update()
    focusTween.current?.update()

    if (hovered) {
      const menuPosition = menuRef.current?.position
      const markerPosition = animals.current?.[hovered] as Vector3 | undefined
      if (menuPosition && markerPosition) {
        const geom = new LineGeometry()
        geom.setPositions(
          [menuPosition.toArray(), markerPosition.toArray()].flat()
        )
        lineRef.current!.geometry = geom
        lineRef.current?.computeLineDistances()
      } else {
        setInteractions([null, null])
      }
    }

    if (focused && !focusTween.current?.isPlaying()) {
      const position = animals.current?.[focused] as Vector3 | undefined
      if (position) {
        menuRef.current?.position.copy(position)
        camera.position.copy(position).normalize().negate()
        camera.zoom = Math.max(1, Math.min(3, -1 / (position.y / 10_000)))
        camera.updateProjectionMatrix()
        camera.dispatchEvent({ type: 'zoom' })
      }
    }
  })

  const { theme } = useTheme()

  const texture = useMemo(() => {
    const canvas = document.createElement('canvas')
    canvas.width = canvas.height = 40
    const ctx = canvas.getContext('2d')
    const texture = new Texture(canvas)
    const center = 40 / 2
    ctx?.beginPath()
    ctx?.arc(center, center, 40 / 2, 0, 2 * Math.PI, false)
    ctx?.closePath()
    ctx!.fillStyle = theme.color.white
    ctx?.fill()
    texture.anisotropy = gl.capabilities.getMaxAnisotropy()
    texture.minFilter = LinearFilter
    texture.needsUpdate = true
    return texture
  }, [gl.capabilities, theme.color.white])

  return (
    <>
      <Line
        position={new Vector3(0, 0, 0)}
        ref={lineRef}
        points={[
          [0, 0, 0],
          [0, 0, 0]
        ]}
        transparent
        opacity={1}
        lineWidth={2}
        color={theme.color.white}
        depthTest={false}
        flatShading={false}
      />

      <points
        ref={ref}
        onPointerOver={(e) => {
          setInteractions(([hovered, focused]) =>
            focused
              ? [hovered, focused]
              : [
                  typeof e.index === 'number'
                    ? Object.keys(animals.current)?.[e.index]
                    : null,
                  null
                ]
          )
        }}
      >
        <pointsMaterial
          attach="material"
          map={texture}
          sizeAttenuation={false}
          depthTest={false}
          transparent
          size={20}
        />
        <bufferGeometry attach="geometry">
          <bufferAttribute
            attachObject={['attributes', 'position']}
            array={[]}
            count={0}
            itemSize={3}
          />
        </bufferGeometry>
      </points>

      <group ref={menuRef}>
        <AnimatePresence>
          {(hovered || focused) && (
            <Menu
              theme={theme}
              initial={{
                height: 40,
                width: 40,
                opacity: 0
              }}
              animate={{
                height: focused ? 120 : 40,
                width: [40, 120],
                opacity: [0, 1],
                transition: { duration: 0.2 }
              }}
              exit={{
                height: 40,
                width: 40,
                opacity: 0,
                transition: { duration: 0.2 }
              }}
              onHoverEnd={() =>
                setInteractions(([, focused]) => [null, focused])
              }
            >
              <AnimatePresence>
                {focused && <Id>{4000 + parseInt(focused)}</Id>}
              </AnimatePresence>
              <Buttons>
                <i
                  className={focused ? 'fas fa-eye-slash' : 'fas fa-eye'}
                  onClick={() =>
                    setInteractions(([hovered]) => [null, hovered])
                  }
                />
                <i className="fas fa-info" />
                <i className="fas fa-exclamation-triangle" />
              </Buttons>
            </Menu>
          )}
        </AnimatePresence>
      </group>
    </>
  )
}

const Menu = styled(motion(ThreeHtml)).attrs({
  center: true,
  zIndexRange: [0, 19]
})`
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.color.gray4};
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  overflow: hidden;
`

const Id = styled(motion.div)`
  position: absolute;
  top: 0;
  bottom: 40px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  font-weight: bold;
`

const Buttons = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background: ${({ theme }) => theme.color.gray5};

  i {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`

export default Tracking
