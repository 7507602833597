import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { useClickOutside } from 'lib/utils'

import Bar from './bar'
import Content from './content'

const Search = () => {
  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = () => setExpanded((state) => !state)

  const [ref, setShouldListenForClickOutside] = useClickOutside<HTMLDivElement>(
    () => setExpanded(false)
  )

  useEffect(() => {
    setShouldListenForClickOutside(expanded)
  }, [expanded, setShouldListenForClickOutside])

  return (
    <Wrapper
      ref={ref}
      initial={{ width: 25, height: 40 }}
      animate={{
        width: expanded ? 500 : 250,
        height: expanded ? 250 : 40,
        transition: {
          duration: 0.25,
          ease: [0, 0.85, 0.15, 1]
        }
      }}
    >
      <Bar expandedMenu={expanded} onMenuToggle={toggleExpanded} />
      {expanded && <Content />}
    </Wrapper>
  )
}

const Wrapper = styled(motion.div)`
  z-index: 20;
  position: absolute;
  top: ${({ theme }) => theme.space.normal};
  left: ${({ theme }) =>
    `calc(${theme.space.normal} + 40px + ${theme.space.normal})`};
  background: ${({ theme }) => `${theme.color.gray4}`};
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
`

export default Search
