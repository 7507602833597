import styled, { keyframes } from 'styled-components'

import { loaderCowLight } from 'lib/assets'

const Loader = () => {
  return (
    <Wrapper>
      <Gif src={loaderCowLight} alt="loading..." />
    </Wrapper>
  )
}
const spin = keyframes`
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
`
const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 256px;
  height: 256px;
  border-radius: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  box-shadow: 0px 0px 100px -20px rgba(0, 0, 0, 0.75) inset;

  &:before {
    content: '';
    border: ${({ theme }) => `4px solid ${theme.color.white}`};
    border-left-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 128px;
    animation: ${spin} 3s linear infinite;
  }

  z-index: 0;
  overflow: hidden;
`

const Gif = styled.img`
  max-width: 128px;
  max-height: 128px;
`

export default Loader
